import { useCallback, useMemo, useState } from "react";
import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAtom } from "jotai";

import DatePicker from "@sellernote/shared/src/admin-ui/components/DatePicker";
import { ADMIN_BID_SERVICE_TYPE_OPTION_LIST } from "@sellernote/shared/src/constants/forwarding/adminBid";
import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "@sellernote/shared/src/jotaiStates/bid";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { BidSearchType } from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  BID_LIST_SEARCH_TYPE_OPTION_LIST,
  BID_PROJECT_STATUS_OPTION_LIST,
  BID_STATUS_OPTION_LIST,
} from "@sellernote/shared/src/utils/common/options";

import { GET_ADMIN_BID_LIST_REQ } from "../../../api-interfaces/shipda-api/admin/adminBid";

const defaultSearchOptions = Object.fromEntries(
  BID_LIST_SEARCH_TYPE_OPTION_LIST.map(({ value }) => [value, undefined])
);

const SELECT_FILTER_LIST: {
  key: keyof GET_ADMIN_BID_LIST_REQ;
  label: string;
  options: { label: string; value: string }[];
}[] = [
  {
    key: "isImport",
    label: "수출/입",
    options: [
      { label: "수입", value: "true" },
      { label: "수출", value: "false" },
    ],
  },
  {
    key: "status",
    label: "상태",
    options: BID_STATUS_OPTION_LIST,
  },
  {
    key: "projectStatus",
    label: "세부 상태",
    options: BID_PROJECT_STATUS_OPTION_LIST,
  },
  {
    key: "serviceType",
    label: "서비스 유형",
    options: ADMIN_BID_SERVICE_TYPE_OPTION_LIST,
  },
];

const ADDRESS_FILTER_LIST: {
  label: string;
  key: keyof GET_ADMIN_BID_LIST_REQ;
}[] = [
  { label: "출발지 항구/공항", key: "departurePortId" },
  { label: "도착지 항구/공항", key: "arrivalPortId" },
];

const ShipmentsSearchFilter = () => {
  const [filterData, setFilterData] = useAtom(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const [searchType, setSearchType] = useState(
    BID_LIST_SEARCH_TYPE_OPTION_LIST.find(({ value }) => filterData[value])
      ?.value || "company"
  );
  const [searchQuery, setSearchQuery] = useState(filterData[searchType] || "");

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const portListOptions = useMemo(
    () =>
      portList?.map(({ id, name, nameEN, code }) => ({
        value: id,
        label: [name, `${nameEN} (${code})`].filter(Boolean).join(" | "),
      })) ?? [],
    [portList]
  );

  const handleFilter = useCallback(
    (key: string, value?: unknown) => {
      setFilterData({
        ...filterData,
        page: 0,
        [key]: value === "all" ? undefined : value,
      });
    },
    [filterData, setFilterData]
  );

  const handleSearchQueryChange = useMemo(
    () =>
      debounce((inputValue: string) => {
        setFilterData({
          ...filterData,
          page: 0,
          ...defaultSearchOptions,
          [searchType]: inputValue,
        });
      }, 500),
    [setFilterData, filterData, searchType]
  );

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        {SELECT_FILTER_LIST.map(({ key, label, options }) => (
          <FormControl key={key} size="small" sx={{ minWidth: "120px" }}>
            <InputLabel>{label}</InputLabel>

            <Select
              label={label}
              value={filterData[key] || "all"}
              size="small"
              onChange={(event) => {
                const { value } = event.target;
                handleFilter(key, value === "all" ? undefined : value);
              }}
            >
              {[{ label: "전체", value: "all" }, ...options].map(
                ({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ))}

        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <FormControl size="small" sx={{ minWidth: "120px" }}>
            <InputLabel>기간 유형</InputLabel>

            <Select label="기간 유형" value="createdAt" size="small">
              {[{ label: "의뢰 생성일", value: "createdAt" }].map(
                ({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <DatePicker
            label="시작일"
            value={filterData.fromDate || null}
            when="start"
            setDate={(value) => handleFilter("fromDate", value)}
          />
          ~
          <DatePicker
            label="종료일"
            value={filterData.toDate || null}
            when="end"
            setDate={(value) => handleFilter("toDate", value)}
          />
          {filterData.fromDate && filterData.toDate && (
            <IconButton
              size="small"
              onClick={() =>
                setFilterData({
                  ...filterData,
                  page: 0,
                  fromDate: undefined,
                  toDate: undefined,
                })
              }
              sx={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                width: "20px",
                height: "20px",
                background: "white",
                border: "1px solid currentColor",
                borderRadius: "50%",
              }}
            >
              <ClearIcon sx={{ width: "12px", height: "12px" }} />
            </IconButton>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <FormControl size="small" sx={{ minWidth: "120px" }}>
            <InputLabel>검색 유형</InputLabel>

            <Select
              label="검색 유형"
              value={searchType}
              size="small"
              onChange={(event) => {
                const value = event.target.value as BidSearchType;
                setSearchType(value);
                setFilterData({
                  ...filterData,
                  page: 0,
                  ...defaultSearchOptions,
                  [value]: searchQuery,
                });
              }}
            >
              {BID_LIST_SEARCH_TYPE_OPTION_LIST.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="검색어"
            type="text"
            value={searchQuery}
            size="small"
            onChange={(event) => {
              const { value } = event.target;
              setSearchQuery(value);
              handleSearchQueryChange(value);
            }}
            InputProps={{
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchQuery("");
                      handleFilter(searchType);
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: "flex", columnGap: "8px" }}>
          {ADDRESS_FILTER_LIST.map(({ label, key }) => (
            <Autocomplete
              key={key}
              value={portListOptions.find(
                ({ value }) => value === filterData[key]
              )}
              options={portListOptions}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label={label} sx={{ width: "300px" }} />
              )}
              onChange={(_, newValue) => handleFilter(key, newValue?.value)}
            />
          ))}
        </Box>
      </Box>

      <Button
        variant="outlined"
        sx={{ alignSelf: "flex-start", flexShrink: 0 }}
        onClick={() => {
          setSearchType("company");
          setSearchQuery("");
          setFilterData({ page: 0, perPage: 20 });
        }}
      >
        초기화
      </Button>
    </Box>
  );
};

export default ShipmentsSearchFilter;
