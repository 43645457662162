import { useCallback, useEffect, useMemo, useState } from "react";

import useFileUrl from "@sellernote/shared/src/hooks/admin/useFileUrl";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ForwardingAdminAttachment } from "@sellernote/shared/src/types/forwarding/trello";

function useFilePreview({
  attachmentFileList,
}: {
  attachmentFileList: ForwardingAdminAttachment[] | undefined;
}) {
  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const [fileIndex, setFileIndex] = useState(0);
  const [s3Url, setS3Url] = useState("");
  // pdf의 경우 파일 안에 2장 이상일 수 있음
  const [pdfFilePageNumber, setPdfFilePageNumber] = useState(1);
  // pagination을 위한 count 값
  const [pdfFilePageCount, setPdfFilePageCount] = useState<number | null>(null);

  const handleFileIndexChange = (v: "increment" | "decrement") => {
    setFileIndex((prev) => (v === "increment" ? prev + 1 : prev - 1));
  };

  const handlePdfFilePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPdfFilePageNumber(page);
  };

  // pdf뷰어 로딩 성공 핸들러 함수
  const handleDocumentLoadSuccess = ({
    numPages,
  }: {
    numPages: number | null;
  }) => {
    setPdfFilePageCount(numPages);
  };

  // CI & PL 파일만 보여주기 위해 필터링한다.
  const ciAndPlAttachmentFileList = useMemo(() => {
    return attachmentFileList?.filter(
      (attachment) => attachment.domain === "CI_PL"
    );
  }, [attachmentFileList]);

  const ciAndPlAttachmentFile = ciAndPlAttachmentFileList?.[fileIndex];

  const fileExtension = ciAndPlAttachmentFile?.name.substring(
    ciAndPlAttachmentFile.name.lastIndexOf(".") + 1
  );

  const isPreviousButtonDisabled = fileIndex === 0;

  const isNextButtonDisabled =
    !ciAndPlAttachmentFileList?.length ||
    fileIndex + 1 === ciAndPlAttachmentFileList?.length;

  // 데이터에 있는 key로 요청해 다운받을 수 있는 s3URL를 응답으로 받고 state를 변경한다.
  const convertToS3Url = useCallback(
    ({ downloadKey }: { downloadKey: string }) => {
      if (!downloadKey) return;

      getFileDownloadUrl(
        {
          pathParams: {
            key: downloadKey,
          },
        },
        {
          onSuccess: ({ data }) => {
            setS3Url(data.url);
          },
        }
      );
    },
    [getFileDownloadUrl]
  );

  // onMount 이후 s3URL를 셋팅하기 위해 useEffect를 사용
  useEffect(() => {
    if (!ciAndPlAttachmentFile?.key) return;

    convertToS3Url({ downloadKey: ciAndPlAttachmentFile.key });
  }, [ciAndPlAttachmentFile, convertToS3Url]);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension ?? "pdf"
  );

  return {
    fileUrl,
    isPdf,
    isHwp,
    hwpRenderDivRef,
    handleFileIndexChange,
    fileExtension,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    handlePdfFilePaginationChange,
    pdfFilePageNumber,
    handleDocumentLoadSuccess,
    pdfFilePageCount,
    ciAndPlAttachmentFile,
  };
}
export default useFilePreview;
