import { UseFormReset } from "react-hook-form";

import { Currency } from "@sellernote/shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/shared/src/types/forwarding/trello";
import { getUnitPriceByCurrencyOfFeeData } from "@sellernote/shared/src/utils/forwarding/admin/tradingStatement";
import { calculateVATPrice } from "@sellernote/shared/src/utils/forwarding/admin/trello";

function useCurrencyFilter({
  setCurrentExchangeRate,
  currency,
  exchangeRateList,
  setCurrency,
  currentExchangeRate,
  withdrawalFormList,
  reset,
  companyType,
  setBaseExchangeRate,
}: {
  setCurrentExchangeRate: (value: number) => void;
  currency: Currency;
  exchangeRateList: ExchangeRate[];
  setCurrency: (value: Currency) => void;
  currentExchangeRate: number;
  withdrawalFormList: WithdrawalFormDetail[];
  reset: UseFormReset<WithdrawalFormListData>;
  companyType: PartnerBusinessArea;
  setBaseExchangeRate: (value: number) => void;
}) {
  const resetWithdrawalDataList = (e: Currency) => {
    const newWithdrawalFormList = withdrawalFormList.map<WithdrawalFormDetail>(
      (withdrawalForm) => {
        if (withdrawalForm.currency === "KRW") {
          return withdrawalForm;
        }
        return {
          ...withdrawalForm,
          currency: e,
        };
      }
    );

    reset({ withdrawalFormList: newWithdrawalFormList });
  };

  const getTotalPriceWithExchangeRate = (v: WithdrawalFormDetail) => {
    if (v.currency === "KRW") {
      // KRW일 때는 exchangeRate를 곱해줄 필요가 없음
      return Number((v.amount * v.unitPrice).toFixed(0));
    }

    return Number((currentExchangeRate * v.amount * v.unitPrice).toFixed(0));
  };

  /**
   *  새로운 출금액 요청 데이터 리스트를 리턴한다.
   *   - totalList는 당일 환율 적용 또는 입력 환율 적용으로 나뉜다.
   */
  const getNewWithdrawalDataList = ({
    withdrawalFormDetail,
    applyTodayCurrency,
  }: {
    withdrawalFormDetail: WithdrawalFormDetail;
    applyTodayCurrency: boolean;
  }) => {
    let totalPrice: number;

    if (applyTodayCurrency) {
      totalPrice = Number(
        (
          getUnitPriceByCurrencyOfFeeData(
            exchangeRateList,
            withdrawalFormDetail
          ) * withdrawalFormDetail.amount
        ).toFixed(0)
      );
    } else {
      totalPrice = getTotalPriceWithExchangeRate(withdrawalFormDetail);
    }

    const vatPrice = calculateVATPrice(
      withdrawalFormDetail,
      totalPrice,
      companyType
    );
    const finalPrice = totalPrice + vatPrice;

    if (companyType === "foreign") {
      return {
        ...withdrawalFormDetail,
        finalPrice: totalPrice,
        vatPrice: 0,
        totalPrice: 0,
      };
    }

    return { ...withdrawalFormDetail, totalPrice, finalPrice, vatPrice };
  };

  /** 당일 환율 값으로 항목값을 모두 갱신 해준다. */
  const handleApplyTodayCurrencyClick = () => {
    const targetExchangeRate =
      exchangeRateList.find((exchange) => {
        return exchange.currency === currency;
      })?.rate || 0;

    const newWithdrawalFormList = withdrawalFormList.map(
      (withdrawalFormDetail: WithdrawalFormDetail) =>
        getNewWithdrawalDataList({
          withdrawalFormDetail,
          applyTodayCurrency: true,
        })
    );

    // 환율 적용 버튼 클릭 시 기준 환율을 당일 환율 값으로 변경한다.
    setBaseExchangeRate(targetExchangeRate);
    setCurrentExchangeRate(targetExchangeRate);

    reset({ withdrawalFormList: newWithdrawalFormList });
  };

  /** 입력된 환율 적용 핸들러 함수 */
  const handleApplyExchangeRateClick = (list: WithdrawalFormDetail[]) => {
    const newWithdrawalFormList = list.map<WithdrawalFormDetail>(
      (withdrawalFormDetail) =>
        getNewWithdrawalDataList({
          withdrawalFormDetail,
          applyTodayCurrency: false,
        })
    );

    // 환율 적용 버튼 클릭 시 기준 환율을 변경한다.
    setBaseExchangeRate(currentExchangeRate);
    reset({ withdrawalFormList: newWithdrawalFormList });
  };

  const handleCurrencySelectChange = (currency: Currency) => {
    const targetExchangeRate =
      exchangeRateList.find((exchange) => {
        return exchange.currency === currency;
      })?.rate || 0;

    setCurrency(currency);
    setCurrentExchangeRate(targetExchangeRate);
    resetWithdrawalDataList(currency);
  };

  const handleExchangeRateChange = (e: number) => {
    setCurrentExchangeRate(e);
  };

  /** 화폐를 모든 항목에 적용한다. */
  const handleApplyCurrencyToAllWithdrawalData = (currency: Currency) => {
    const newWithdrawalFormList = withdrawalFormList.map<WithdrawalFormDetail>(
      (n) => {
        // 화폐 일괄적용 시 항목을 해당 환율로 재계산한다.
        return getNewWithdrawalDataList({
          withdrawalFormDetail: { ...n, currency },
          applyTodayCurrency: false,
        });
      }
    );

    reset({ withdrawalFormList: newWithdrawalFormList });
  };

  return {
    handleApplyTodayCurrencyClick,
    handleApplyExchangeRateClick,
    handleCurrencySelectChange,
    handleExchangeRateChange,
    handleApplyCurrencyToAllWithdrawalData,
  };
}

export default useCurrencyFilter;
