import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, Tooltip } from "@mui/material";
import { useAtom } from "jotai";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/shared/src/jotaiStates/trello";

export default function DeleteButton({
  index,
  showsDeleteButton,
  isFormDisabled,
}: {
  index: number;
  showsDeleteButton: boolean;
  isFormDisabled: boolean;
}) {
  const [shipmentScheduleList, setShipmentScheduleList] = useAtom(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  if (!showsDeleteButton) return null;

  const handleDeleteButtonClick = () => {
    /** 삭제된 폼의 arrival 정보를 저장 */
    const deletedScheduleArrivalPort = shipmentScheduleList[index]?.arrivalPort;

    const deletedScheduleArrivalDate = shipmentScheduleList[index]?.ETA;

    const updatedList = [...shipmentScheduleList];

    /** 삭제된 폼의 이전 index에 삭제된 폼의 정보를 넣어줌 */
    updatedList[index - 1].arrivalPort = deletedScheduleArrivalPort;

    updatedList[index - 1].ETA = deletedScheduleArrivalDate;
    updatedList[index - 1].ATA = null;

    /** 해당 인덱스의 요소를 배열에서 제거 */
    updatedList.splice(index, 1);

    setShipmentScheduleList(updatedList);
  };

  return (
    <Tooltip title={"항목삭제"} style={{ fontSize: 24 }}>
      {/* MUI에서 Add a simple wrapper element, such as a `span` 에러를 표시해서 span을 추가 */}
      <span>
        <IconButton
          aria-label="delete-form"
          style={{ marginLeft: 30, alignSelf: "center" }}
          onClick={handleDeleteButtonClick}
          disabled={isFormDisabled}
        >
          <CancelIcon fontSize="large" />
        </IconButton>
      </span>
    </Tooltip>
  );
}
