import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from "@mui/material";

import { HBLInfo } from "@sellernote/shared/src/types/forwarding/trello";

import AttachedRider from "./_components/AttachedRider";
import BLInfo from "./_components/BLInfo";
import BLNumber from "./_components/BLNumber";
import CargoInfo from "./_components/CargoInfo";
import RequestButton from "./_components/RequestButton";
import RouteInfo from "./_components/RouteInfo";
import ShipperInfo from "./_components/ShipperInfo";
import VesselAndTerms from "./_components/VesselAndTerms";
import Styled from "./index.styles";

function Form({
  shipmentId,
  HBLData,
}: {
  shipmentId: number;
  HBLData: HBLInfo;
}) {
  const [deletedContainerIdList, setDeletedContainerIdList] = useState<
    number[]
  >([]);

  const addDeletedContainerId = (id: number) => {
    setDeletedContainerIdList((prev) => [...prev, id]);
  };

  const methods = useForm<HBLInfo>({
    mode: "onChange",
    defaultValues: HBLData,
  });

  const { watch, reset } = methods;

  const containerList = watch("containers");

  const goodsList = watch("itemName");

  // 컨테이너정보가 6개 이상이거나 아이템 목록이 11개 이상인 경우 BL의 첫번째 장이 아닌 attachedRider라는 두번쨰 장을 보여줘야 함
  const showsAttachedRider =
    (containerList && containerList?.length > 5) ||
    (goodsList && goodsList?.length > 10) ||
    false;

  // BL문서와 맞추기 위해 해당 폼의 텍스트필드 스타일만 직접 수정
  const textFieldSmallFontSizeTheme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          InputProps: {
            style: {
              fontSize: "9px",
              padding: 0,
            },
          },
          // helperText와 입력 필드 사이 간격 줄임
          FormHelperTextProps: {
            sx: {
              margin: 0,
              lineHeight: 1.4,
              fontSize: "8px",
            },
          },
        },
      },
    },
  });

  /**
   * 임시 저장, 저장할 때매다 컨테이너 정보를 업데이트
   * 컨테이너는 추가될 때마다 서버에서 ID를 부여하므로 삭제 로직 처리를 위해 동기화 필요
   */
  useEffect(() => {
    reset({ ...methods.getValues(), containers: HBLData.containers });
  }, [HBLData, methods, reset]);

  return (
    <Styled.container>
      <FormProvider {...methods}>
        {/* 해당 페이지에서만 전체 적용되는 커스텀 스타일 추가 */}
        <ThemeProvider theme={textFieldSmallFontSizeTheme}>
          <RequestButton
            shipmentId={shipmentId}
            deletedContainerIdList={deletedContainerIdList}
            setDeletedContainerIdList={setDeletedContainerIdList}
            containerList={HBLData.containers ?? []}
          />

          <BLNumber />

          <ShipperInfo />

          <RouteInfo />

          <CargoInfo
            showsAttachedRider={showsAttachedRider}
            addDeletedContainerId={addDeletedContainerId}
          />

          <BLInfo />

          <VesselAndTerms />

          {showsAttachedRider && (
            <AttachedRider addDeletedContainerId={addDeletedContainerId} />
          )}
        </ThemeProvider>
      </FormProvider>
    </Styled.container>
  );
}

export default Form;
