import { useCallback, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import ADMIN_ZONE_ADDRESS_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/shared/src/types/forwarding/trello";
import { checkIfHaveAuthorityToChangeTrelloDetail } from "@sellernote/shared/src/utils/forwarding/admin/trello";

import Modal from "../../../../../../../components/Modal";

function EndAddressFormModal({
  trelloDetail,
  opensEndAddressFormModal,
  setOpensEndAddressFormModal,
  bidAccountPayableId,
}: {
  opensEndAddressFormModal: boolean;
  setOpensEndAddressFormModal: (value: boolean) => void;
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number | undefined;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const history = useHistory();

  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const hasDestinationButNoEndAddressDetail = (() => {
    const { zoneId, endAddress, endAddressDetail, serviceType } = trelloDetail;

    if (
      serviceType === "consolidation" &&
      zoneId &&
      endAddress &&
      endAddressDetail === null
    )
      return true;

    return false;
  })();

  const [opensDaumPostcode, setOpensDaumPostcode] = useState(false);

  const [zoneId, setZoneId] = useState(trelloDetail.zoneId || 0);

  const [endAddress, setEndAddress] = useState(
    hasDestinationButNoEndAddressDetail
      ? trelloDetail.zone?.name || ""
      : trelloDetail.endAddress || ""
  );

  const [endAddressDetail, setEndAddressDetail] = useState(
    hasDestinationButNoEndAddressDetail
      ? trelloDetail.endAddress || ""
      : trelloDetail.endAddressDetail || ""
  );

  const { data: zoneAddress, isFetching: isZoneAddressFetching } =
    ADMIN_ZONE_ADDRESS_QUERY.useGetAdminZoneAddress({
      address: endAddress ?? "",
    });

  const {
    mutate: registerInland,
    ResponseHandler: ResponseHandlerOfRegisterInland,
  } = TRELLO_BID_QUERY.useRegisterInland(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      // 도착지 등록 시 거래명세서 페이지에서 관련 항목을 추가해야한다.
      history.push(
        `/tradingStatement/invoice/${trelloDetail.id}/${
          trelloDetail.isImport ? "import" : "export"
        }`
      );
    },

    customizeMessage: () => ({
      title: "내륙운송을 수정했습니다.",
    }),
  });

  const {
    mutate: cancelInlandTransport,
    ResponseHandler: ResponseHandlerOfUseCancelInlandTransport,
  } = TRELLO_BID_QUERY.useCancelInlandTransport(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      setOpensEndAddressFormModal(false);
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },

    customizeMessage: () => ({
      title: "내륙 운송을 취소했습니다.",
    }),
  });

  const {
    mutate: editInlandType,
    ResponseHandler: ResponseHandlerOfEditInlandType,
  } = TRELLO_BID_QUERY.useEditInlandType({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setOpensEndAddressFormModal(false);
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },

      customizeMessage: () => ({
        title: "내륙운송을 수정했습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const handleInlandTransportChange = () => {
    // 내륙주소, 상세 주소가 없이 요청했을 때는 내륙운송을 취소 요청을한다.
    if (!endAddress && !endAddressDetail && bidAccountPayableId) {
      handleSnackbarOpen("주소와 상세주소를 입력해주세요.", "warning");
      return;
    }

    if (!zoneId) {
      handleSnackbarOpen("주소지를 입력해주세요.", "warning");
      return;
    }

    editInlandType({
      bidAccountPayableId,
      detailFlag: false,
      zoneId,
      endAddress,
      endAddressDetail,
    });
  };

  const handleInlandTypeRegisterAndMoveToTradingStatement = () => {
    if (!zoneId) {
      handleSnackbarOpen("주소지를 입력해주세요.", "warning");
      return;
    }

    registerInland({
      bidAccountPayableId,
      inlandFlag: true,
      zoneId,
      endAddress,
      endAddressDetail,
    });
  };

  const handleInlandTypeCancel = () => {
    if (!trelloDetail.zone && !trelloDetail.endAddress) {
      handleSnackbarOpen("도착지 정보가 등록되지 않았습니다.", "warning");
      return;
    }
    if (!bidAccountPayableId) {
      handleSnackbarOpen("내륙 파트너 정보가 없습니다.", "warning");
      return;
    }

    cancelInlandTransport({
      bidAccountPayableId,
      inlandFlag: false,
    });
  };

  const ModalRef = useCallback(
    (node) => {
      if (node) {
        const zoneId = zoneAddress?.find(
          ({ roadAddress, jibunAddress }) =>
            roadAddress === endAddress || jibunAddress === endAddress
        )?.id;

        if (zoneId) {
          setZoneId(zoneId);
        }
      }
    },
    [endAddress, zoneAddress]
  );

  return (
    <Modal
      isOpened={opensEndAddressFormModal}
      handleClose={() => {
        setOpensEndAddressFormModal(false);
      }}
      modalBody={
        <Box sx={{ width: "400px" }} ref={ModalRef}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h6">도착지 내륙</Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}
            >
              <TextField
                label="주소"
                value={endAddress}
                size="small"
                InputProps={{
                  readOnly: true,
                  endAdornment: endAddress && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setEndAddress("");
                          setZoneId(0);
                        }}
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ flexGrow: 1 }}
              />

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpensDaumPostcode(!opensDaumPostcode)}
              >
                주소검색 {opensDaumPostcode && "닫기"}
              </Button>
            </Box>

            {opensDaumPostcode && (
              <DaumPostcodeEmbed
                showMoreHName={true}
                onComplete={({ address }) => {
                  setEndAddress(address);
                  setOpensDaumPostcode(false);
                }}
              />
            )}

            <Box>
              <TextField
                label="상세주소"
                fullWidth
                size="small"
                onChange={(e) => {
                  setEndAddressDetail(e.target.value);
                }}
                value={endAddressDetail}
              />
            </Box>

            <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
              <Button
                color="error"
                variant="outlined"
                className="sads"
                onClick={handleInlandTypeCancel}
              >
                내륙 운송 취소
              </Button>

              <Button
                variant="contained"
                disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                  currentAdminAuthInfo?.authority,
                  trelloDetail.projectStatus
                )}
                onClick={() => {
                  if (isZoneAddressFetching) {
                    return;
                  }
                  if (
                    !trelloDetail.endAddress &&
                    trelloDetail.endAddress !== ""
                  ) {
                    handleInlandTypeRegisterAndMoveToTradingStatement();
                  } else {
                    handleInlandTransportChange();
                  }
                }}
              >
                {isZoneAddressFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "수정"
                )}
              </Button>
            </Box>

            {ResponseHandlerOfRegisterInland}
            {ResponseHandlerOfUseCancelInlandTransport}
            {ResponseHandlerOfEditInlandType}
          </Box>
        </Box>
      }
    />
  );
}

export default EndAddressFormModal;
