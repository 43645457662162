import { useCallback, useMemo } from "react";

import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ContainerTypeForCustoms as ContainerType } from "@sellernote/shared/src/types/forwarding/bid";

/**
 * 컨테이너 정보 > 컨테이너 정보, 세관 Code 조회 및 세관 Code 유효성 검사 훅
 *
 * 사용처
 *   1. 운영관리 카드 상세 > 컨테이너 정보
 *   2. 운영관리 카드 상세 > 적하목록 제출
 *
 * @returns 컨테이너 정보 리스트, 세관 Code 리스트, 세관 Code 유효성 검사 함수
 */
export default function useGetAndValidateContainerType() {
  const { data } = TRELLO_BID_QUERY.useGetContainerType();

  /** ContainerType 리스트 */
  const containerTypeList = useMemo(() => {
    return data?.optionsForShipda || [];
  }, [data?.optionsForShipda]);

  /** 세관 Code 리스트 */
  const containerTypeForCustomsList = useMemo(() => {
    return data?.optionsForCustoms || [];
  }, [data?.optionsForCustoms]);

  const containerTypeValidationMap = useMemo(() => {
    return new Set(containerTypeForCustomsList);
  }, [containerTypeForCustomsList]);

  /** 세관 Code 유효성 검사 함수 */
  const validateContainerTypeForCustoms = useCallback(
    (list: (ContainerType | null)[]) => {
      return list.every((type) => {
        if (!type) return false;

        return containerTypeValidationMap.has(type);
      });
    },
    [containerTypeValidationMap]
  );

  return {
    containerTypeList,
    containerTypeForCustomsList,
    validateContainerTypeForCustoms,
  };
}
