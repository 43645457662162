import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ForwardingAdminUserListItem } from "@sellernote/shared/src/types/forwarding/adminUser";
import { formatDate } from "@sellernote/shared/src/utils/common/date";

import Modal from "../../../../components/Modal";

export default function ManagerHistory({
  teamId,
  shipmentId,
  adminUserList = [],
}: {
  teamId: number;
  shipmentId: number;
  adminUserList: ForwardingAdminUserListItem[];
}) {
  const { data: managerHistoryList } =
    ADMIN_BID_QUERY.useGetForwardingManagerHistory({
      teamId: teamId,
      bidId: shipmentId,
    });

  const [opensModal, setOpensModal] = useState(false);

  const getAdminName = (adminId: number) => {
    const user = adminUserList.find(({ id }) => id === adminId);
    return user?.name || "-";
  };

  return (
    <>
      <Button
        variant="text"
        className="sads"
        hidden={!managerHistoryList?.length}
        onClick={() => setOpensModal(true)}
      >
        변경이력 보기
      </Button>

      <Modal
        isOpened={opensModal}
        handleClose={() => setOpensModal(false)}
        modalBody={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "24px",
              width: "300px",
            }}
          >
            <Typography variant="h6" className="sads">
              담당자 변경 이력
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "8px",
              }}
            >
              {managerHistoryList?.map(
                ({ forwardingManagerId, changedAt }, index) => (
                  <Box
                    key={`${forwardingManagerId}-${index}`}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className="sads">
                      {getAdminName(forwardingManagerId)}
                    </Typography>

                    <Typography className="sads" color={"#808080"}>
                      {formatDate({
                        date: changedAt,
                        type: "YY_MM_DD_HH_mm_ss",
                      })}
                    </Typography>
                  </Box>
                )
              )}
            </Box>

            <Button
              variant="contained"
              size="small"
              className="sads"
              onClick={() => setOpensModal(false)}
            >
              확인
            </Button>
          </Box>
        }
      />
    </>
  );
}
