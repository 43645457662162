import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import { ContainerInfo as ContainerInfoType } from "./types";

import ContainerSaveButton from "./ContainerSaveButton";
import ContainerTable from "./ContainerTable";
import Header from "./Header";

export default function ContainerInfo({ shipmentId }: { shipmentId: number }) {
  const { data } =
    TRELLO_BID_QUERY.useGetOperationManagementCardDetailContainerInfo({
      shipmentId,
    });

  const [deletedIdList, setDeletedIdList] = useState<number[]>([]);

  const form = useForm<{ containerList: ContainerInfoType[] }>({
    defaultValues: { containerList: data?.containers ?? [] },
  });

  const {
    fields: containerList,
    append: addContainer,
    remove: removeContainer,
    update: updateContainer,
  } = useFieldArray({
    control: form.control,
    name: "containerList",
    keyName: "tempId",
  });

  /**
   * useQuery의 onSuccess가 deprecated 됐기 때문에, useEffect를 사용해 reset
   */
  useEffect(() => {
    if (data) {
      form.reset({ containerList: data.containers });
    }
  }, [data, form]);

  return (
    <Accordion sx={{ mt: 2 }} className="sads">
      <AccordionSummary expandIcon={<ExpandMore />} className="sads">
        컨테이너 정보
      </AccordionSummary>

      <AccordionDetails className="sads">
        <FormProvider {...form}>
          <Box width={"100%"}>
            <Header addContainer={addContainer} />

            <ContainerTable
              containerList={containerList}
              setDeletedIdList={setDeletedIdList}
              removeContainer={removeContainer}
              updateContainer={updateContainer}
            />

            <ContainerSaveButton
              shipmentId={shipmentId}
              containerList={containerList}
              deletedIdList={deletedIdList}
              setDeletedIdList={setDeletedIdList}
            />
          </Box>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
}
