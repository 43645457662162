import { useCallback } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";

function QuotationDownload({ bidId }: { bidId: number }) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: downloadBidQuotation,
    ResponseHandler: ResponseHandlerOfDownloadBidQuotation,
  } = ADMIN_BID_QUERY.useDownloadBidQuotation(bidId);

  const handleBidQuotationDownload = useCallback(() => {
    downloadBidQuotation(
      {},
      {
        onSuccess: ({ data: successData }) => {
          window.location.href = successData.preSignedUrl.url;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [downloadBidQuotation, handleSnackbarOpen]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleBidQuotationDownload}
        endIcon={<DownloadIcon />}
        color="secondary"
      >
        견적서 다운로드
      </Button>

      {ResponseHandlerOfDownloadBidQuotation}
    </>
  );
}

export default QuotationDownload;
