import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

export default function useCheckNecessaryFile({
  shipmentId,
  isImport,
}: {
  shipmentId: number;
  isImport: boolean;
}) {
  const { data: attachments } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: shipmentId,
  });

  /** 수입의 경우 운영 관리 상세 > 서류 업로드 > AN, BL 파일이 업로드 됐는지 확인 */
  const hasNecessaryFileForImport =
    attachments?.find((doc) => doc.domain === "arrivalNotice") &&
    attachments?.find((doc) => doc.domain === "BL");

  /** 수출의 경우 운영 관리 상세 > 서류 업로드 > AN, 수출신고필증 파일이 업로드 됐는지 확인 */
  const hasNecessaryFileForExport =
    attachments?.find((doc) => doc.domain === "exportDeclaration") &&
    attachments?.find((doc) => doc.domain === "BL");

  const hasNecessaryFile = isImport
    ? hasNecessaryFileForImport
    : hasNecessaryFileForExport;

  return {
    hasNecessaryFile,
  };
}
