import { Box, Typography } from "@mui/material";

import ADMIN_PARTNER_MANAGEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { FreightType } from "@sellernote/shared/src/types/common/common";
import { AccountPaymentCondition } from "@sellernote/shared/src/types/forwarding/adminAccount";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import { TrelloBidManagement } from "@sellernote/shared/src/types/forwarding/trello";
import { changePurchaseDomainToKr } from "@sellernote/shared/src/utils/forwarding/admin/trello";

import BLTooltip from "./BLTooltip";

export default function Header({
  partnerName,
  partnerId,
  accountId,
  partnerType,
  onAccountIdChange,
  onAccountPaymentChange,
  isWithdrawalRequested,
  management,
  freightType,
}: {
  partnerName: string;
  partnerId: number;
  accountId: number | undefined;
  partnerType: PartnerBusinessArea;
  onAccountIdChange: (id: number | null) => void;
  onAccountPaymentChange: (payment: AccountPaymentCondition) => void;
  isWithdrawalRequested: boolean;
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const { data } = ADMIN_PARTNER_MANAGEMENT_QUERY.useGetPartnerDetail({
    id: partnerId,
    /**
     * 파트너에 매핑된 거래처가 하나일 경우, 상태에 거래처 아이디를 저장
     * 매핑된 거래처가 하나 이상일 경우는 거래처 선택 컴포넌트를 노출, 선택시 저장
     */
    onSuccess: ({ financialAccounts }) => {
      if (financialAccounts.length === 1) {
        onAccountIdChange(financialAccounts[0].id);
      }
    },

    enabled: partnerType !== "customsDuty",
  });

  const { financialAccounts = [] } = data || {};

  const hasOnlyOneMappedAccount = financialAccounts.length === 1;

  /** 거래처에 매핑된 파트너가 한 개일 경우, 파트너명과 계좌번호를 노출 */
  const accountNameWithBankInfo = (() => {
    if (!financialAccounts.length) return "";
    const account = financialAccounts[0];

    return `${account.name} - ${account.bankAccount ?? "계좌 정보 없음"}`;
  })();

  const isCustomsDuty = partnerType === "customsDuty";

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="h6">
        매입자료 입력 -{" "}
        {isCustomsDuty
          ? "세관"
          : `${changePurchaseDomainToKr(partnerType)} ${partnerName}`}
      </Typography>

      <BLTooltip management={management} freightType={freightType} />

      {/** 운영 환경에서 거래처 매핑이 돼있지 않아 임시 주석처리 */}
      {/* {!isCustomsDuty && (
        <>
          {hasOnlyOneMappedAccount ? (
            <Typography variant="h6" sx={{ color: blue[600] }}>
              {accountNameWithBankInfo}
            </Typography>
          ) : (
            <AccountSelect
              accountId={accountId}
              onAccountIdChange={onAccountIdChange}
              onAccountPaymentChange={onAccountPaymentChange}
              financialAccounts={financialAccounts}
              isWithdrawalRequested={isWithdrawalRequested}
            />
          )}
        </>
      )} */}
    </Box>
  );
}
