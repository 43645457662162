import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { HBLInfo } from "@sellernote/shared/src/types/forwarding/trello";

/**
 * 컨테이너정보와 물품정보 인풋을 추가하거나 제거하는 로직이 있는 커스텀 훅
 */
function useItemAdd() {
  const { watch, setValue } = useFormContext<HBLInfo>();

  const containerList = watch("containers");

  const itemNameList = watch("itemName");

  /** 한번에 추가할 컨테이너정보 인풋의 개수 */
  const [toBeAddedContainerTotal, setToBeAddedContainerTotal] = useState("1");
  /** 한번에 추가할 물품정보 인풋의 개수 */
  const [toBeAddedItemNameTotal, setToBeAddedItemNameTotal] = useState("1");

  const handleContainerRemove = (itemIndex: number) => {
    const removedContainerList = containerList?.filter(
      (_, index) => index !== itemIndex
    );

    setValue("containers", removedContainerList || []);
  };

  const handleContainerAdd = () => {
    if (containerList) {
      if (toBeAddedContainerTotal === "1") {
        setValue("containers", [
          ...containerList,
          { containerNo: "", sealNo: "", containerType: "" },
        ]);

        return;
      }

      const emptyContainerList = Array(Number(toBeAddedContainerTotal))
        .fill(null)
        .map(() => ({ containerNo: "", sealNo: "", containerType: "" }));

      setValue("containers", [...containerList, ...emptyContainerList]);
      return;
    }

    return;
  };

  const handleItemNameRemove = (itemIndex: number) => {
    const removedItemNameList = itemNameList?.filter(
      (_, index) => index !== itemIndex
    );

    setValue("itemName", removedItemNameList || []);
  };

  const handleItemNameAdd = () => {
    if (itemNameList) {
      if (toBeAddedItemNameTotal === "1") {
        setValue("itemName", [...itemNameList, ""]);

        return;
      }

      const emptyItemNameList = Array(Number(toBeAddedItemNameTotal))
        .fill(null)
        .map(() => "");

      setValue("itemName", [...itemNameList, ...emptyItemNameList]);
      return;
    }

    return;
  };

  return {
    handleContainerAdd,
    handleContainerRemove,
    handleItemNameAdd,
    setToBeAddedContainerTotal,
    handleItemNameRemove,
    setToBeAddedItemNameTotal,
    toBeAddedContainerTotal,
    toBeAddedItemNameTotal,
  };
}

export default useItemAdd;
