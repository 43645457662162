import { useRef, useState } from "react";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/trello";
import { ContainerInfoForAN } from "@sellernote/shared/src/types/forwarding/trello";

import {
  EXPORT_KEYS,
  IMPORT_KEYS,
} from "../CargoManifestInfoForm/EditAndSaveButton/utils/constants";
import { ContainerFormChangeProps } from "../types";

const INITIAL_CONTAINER: Readonly<ContainerInfoForAN> = {
  containerNo: "",
  sealNo: "",
  containerTypeForCustoms: null,
  packageCount: null,
};

export default function useHandleManifestInfo({
  cargoManifestInfo,
}: {
  cargoManifestInfo: CargoManifestInfoProps;
}) {
  const [form, setForm] = useState<CargoManifestInfoProps>(cargoManifestInfo);

  const prevForm = useRef(cargoManifestInfo);

  /** cargoManifestInfo가 수정되어 다시 불러왔을 때 setState */
  if (prevForm.current !== cargoManifestInfo) {
    setForm(cargoManifestInfo);
    prevForm.current = cargoManifestInfo;
  }

  const handleContainerFormChange = ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => {
    setForm((prevState) => {
      const updatedContainerList = [...(prevState.containers ?? [])];

      updatedContainerList[index] = {
        ...updatedContainerList[index],
        [key]: value,
      };

      return { ...prevState, containers: updatedContainerList };
    });
  };

  const handleContainerAdd = () => {
    setForm((prevState) => {
      const updatedContainerList = [
        ...(prevState.containers ?? []),
        INITIAL_CONTAINER,
      ];

      return { ...prevState, containers: updatedContainerList };
    });
  };

  const handleContainerDelete = ({ index }: { index: number }) => {
    setForm((prevState) => {
      if (!prevState.containers) return prevState;

      const updatedContainerList = prevState.containers?.filter(
        (_, i) => i !== index
      );

      return { ...prevState, containers: updatedContainerList };
    });
  };

  const handleFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (
      IMPORT_KEYS.includes(name as keyof CargoManifestInfoProps["importation"])
    ) {
      setForm((prevForm) => ({
        ...prevForm,
        importation: {
          ...prevForm.importation,
          [name]: value,
        },
      }));

      return;
    }

    if (
      EXPORT_KEYS.includes(name as keyof CargoManifestInfoProps["exportation"])
    ) {
      setForm((prevForm) => ({
        ...prevForm,
        exportation: {
          ...prevForm.exportation,
          [name]: value,
        },
      }));

      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [name]: name === "packageCount" ? Number(value) : value,
    }));
  };

  const handleEstimatedTimeChange = (
    value: string | null,
    isImport: boolean
  ) => {
    if (isImport) {
      setForm({ ...form, importation: { ...form.importation, ETA: value } });
      return;
    }

    setForm({ ...form, exportation: { ...form.exportation, ETD: value } });
  };

  return {
    form,
    handleContainerFormChange,
    handleContainerAdd,
    handleContainerDelete,
    handleFormChange,
    handleEstimatedTimeChange,
  };
}
