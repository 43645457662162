import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/trello";

/** 응답값 중 특정 값이 포함돼있으면 border 하이라이팅 */
const highlightField = (
  form: CargoManifestInfoProps,
  name:
    | keyof CargoManifestInfoProps
    | keyof CargoManifestInfoProps["importation"]
    | keyof CargoManifestInfoProps["exportation"]
) => {
  if (
    name === "spclCode" ||
    name === "assignCode" ||
    name === "terminalCode" ||
    name === "MSN" ||
    name === "ETA"
  ) {
    return !form["importation"][name] ? {} : { border: "1.5px solid #ec3a3a" };
  }

  if (
    name === "partialShipment" ||
    name === "declarationNumber" ||
    name === "ETD"
  ) {
    return !form["exportation"][name] ? {} : { border: "1.5px solid #ec3a3a" };
  }

  return !form[name] ? {} : { border: "1.5px solid #ec3a3a" };
};

export { highlightField };
