import { useFormContext } from "react-hook-form";

import { HBLInfo } from "@sellernote/shared/src/types/forwarding/trello";

import Styled from "./index.styles";

function BLNumber() {
  const { watch } = useFormContext<HBLInfo>();

  const blNumber = watch("blNumber");

  return (
    <Styled.container>
      <Styled.infoContainer>
        <div className="title">BILL OF LADING</div>

        <div className="subtitle">
          For combined Transport of <br />
          Port-To-Port Shipment
        </div>
      </Styled.infoContainer>

      <Styled.infoContainer>
        <div className="title">BL No.</div>

        <div className="blNumber">{blNumber}</div>
      </Styled.infoContainer>
    </Styled.container>
  );
}

export default BLNumber;
