import { useFormContext } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, SelectChangeEvent } from "@mui/material";

import { ADMIN_BID_CONTAINER_TYPE_OPTION_LIST } from "@sellernote/shared/src/constants/forwarding/adminBid";
import { HBLInfo } from "@sellernote/shared/src/types/forwarding/trello";

import SelectWithReactHookForm from "../../../../../../../../../../../components/SelectWithReactHookForm";
import TextFieldWithReactHookForm from "../../../../../../../../../../../components/TextFieldWithReactHookForm";

import useItemAdd from "../../../../_hooks/useItemAdd";
import ItemAddSelect from "../ItemAddSelect";
import Styled from "./index.styles";

function AttachedRider({
  addDeletedContainerId,
}: {
  addDeletedContainerId: (id: number) => void;
}) {
  const { control, watch } = useFormContext<HBLInfo>();

  const {
    shipName,
    voyageNumber,
    pol,
    pod,
    freightType,
    freightPaymentType,
    containers: containerList,
    itemName: itemNameList,
    blNumber,
  } = watch();

  const {
    handleContainerAdd,
    handleContainerRemove,
    handleItemNameAdd,
    setToBeAddedContainerTotal,
    handleItemNameRemove,
    setToBeAddedItemNameTotal,
    toBeAddedContainerTotal,
    toBeAddedItemNameTotal,
  } = useItemAdd();

  return (
    <Styled.container>
      <Styled.title>ATTACHED RIDER</Styled.title>

      <Styled.topDivider></Styled.topDivider>

      <Styled.routeContainer>
        <Styled.ship>
          <div className="title">VESSEL / VOY</div>

          <div className="content">{`${shipName} ${voyageNumber}`}</div>
        </Styled.ship>

        <Styled.blNumber>
          <div className="title">H.B/L</div>

          <div className="desc">{blNumber}</div>
        </Styled.blNumber>

        <Styled.route>
          <div className="title">POL</div>

          <div className="content">{pol}</div>
        </Styled.route>

        <Styled.route>
          <div className="title">POD</div>

          <div className="content">{pod}</div>
        </Styled.route>
      </Styled.routeContainer>

      <Styled.goodsAndContainerSection>
        <Styled.goodsAndContainerColumn>
          <Styled.goodsAndContainerRow>
            <Styled.containerNumber>
              <div className="border-top"></div>

              <div className="title">MARKS AND NUMBERS</div>

              <div className="subtitle">(CONTAINER NO. & SEAL NO.)</div>

              <div className="content">
                {freightType === "FCL" ? "CY/CY" : "CFS/CFS"}
              </div>
            </Styled.containerNumber>

            <Styled.unit>
              <div className="border-top"></div>

              <div className="title">NO. OF PKGS OR UNITS</div>

              <TextFieldWithReactHookForm
                name="packingType"
                control={control}
                fullWidth
              />
            </Styled.unit>
          </Styled.goodsAndContainerRow>

          {containerList?.map((container, index) => {
            const firstEmptyStringIndex = containerList.findIndex(
              (item) => item.containerNo === ""
            );

            /**
             * attachedRider로 내려가는 경우 인풋에 오토포커스 여부를 추가
             *   - 물품정보가 10개 초과인 경우도 내려갈 수 있기 떄문에 추가 검증한다.
             *   - 첫번째 빈스트링 인풋에 포커스가 가도록 설정한다.
             */
            const isAutoFocus =
              (itemNameList &&
                itemNameList?.length < 11 &&
                index === firstEmptyStringIndex) ||
              false;

            return (
              <div key={index}>
                <TextFieldWithReactHookForm
                  name={`containers.${index}.containerNo`}
                  control={control}
                  required={true}
                  autoFocus={isAutoFocus}
                  pattern={/^[A-Za-z0-9]{11}$/}
                  errorMessage="영문+숫자 11자리입니다."
                  sx={{ width: 80 }}
                />

                <TextFieldWithReactHookForm
                  name={`containers.${index}.sealNo`}
                  control={control}
                  required={true}
                  sx={{ width: 80 }}
                />

                <SelectWithReactHookForm
                  name={`containers.${index}.containerType`}
                  control={control}
                  options={ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.map(
                    (option) => {
                      return option.value;
                    }
                  )}
                  required={true}
                  size="small"
                  sx={{
                    fontSize: "9px",
                    padding: "4px 2px 0px",
                    height: "22px",
                    width: "60px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 200,
                      },
                    },
                  }}
                />

                <IconButton
                  aria-label="delete"
                  size="small"
                  color="error"
                  onClick={() => {
                    const isContainerFromServer = "id" in container;

                    if (isContainerFromServer && container.id) {
                      addDeletedContainerId(container.id);
                    }

                    handleContainerRemove(index);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
            );
          })}

          <ItemAddSelect
            itemType="container"
            value={toBeAddedContainerTotal}
            onSelectChange={(e: SelectChangeEvent) =>
              setToBeAddedContainerTotal(e.target.value)
            }
            onContainerItemAdd={handleContainerAdd}
          />
        </Styled.goodsAndContainerColumn>

        <Styled.descriptionOfGoods>
          <div className="border-top"></div>
          <div className="title">Description of goods</div>

          {itemNameList?.map((_, index) => {
            const firstEmptyStringIndex = itemNameList.findIndex(
              (item) => item === ""
            );

            /**
             * attachedRider로 내려가는 경우 인풋에 오토포커스 여부를 추가
             *   - 컨테이너 정보가 5개 초과인 경우도 내려갈 수 있기 떄문에 추가 검증한다.
             *   - 첫번째 빈스트링 인풋에 포커스가 가도록 설정한다.
             */
            const isAutoFocus =
              (containerList &&
                containerList?.length < 6 &&
                index === firstEmptyStringIndex) ||
              false;

            return (
              <Box
                key={index}
                display="flex"
                alignItems={"center"}
                width={"100%"}
              >
                <TextFieldWithReactHookForm
                  name={`itemName.${index}`}
                  control={control}
                  fullWidth
                  autoFocus={isAutoFocus}
                  multiline={true}
                  required={true}
                />

                <IconButton
                  aria-label="delete"
                  size="small"
                  color="error"
                  disabled={itemNameList.length === 1}
                  onClick={() => handleItemNameRemove(index)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
            );
          })}

          <ItemAddSelect
            itemType="goods"
            value={toBeAddedItemNameTotal}
            onSelectChange={(e: SelectChangeEvent) =>
              setToBeAddedItemNameTotal(e.target.value)
            }
            onContainerItemAdd={handleItemNameAdd}
          />

          {freightPaymentType && (
            <div className="content">
              {freightPaymentType === "cc"
                ? '"FREIGHT COLLECT"'
                : '"FREIGHT PREPAID"'}
            </div>
          )}
        </Styled.descriptionOfGoods>
      </Styled.goodsAndContainerSection>

      <Styled.bottomDivider />
    </Styled.container>
  );
}

export default AttachedRider;
