import { useState } from "react";

import { AccountPaymentCondition } from "@sellernote/shared/src/types/forwarding/adminAccount";

export default function useHandleSelectedAccount() {
  const [accountId, setAccountId] = useState<number | undefined>(undefined);
  const [accountPayment, setAccountPayment] = useState<
    AccountPaymentCondition | undefined
  >(undefined);

  const handleAccountIdChange = (id: number | null) => {
    setAccountId(id ? id : undefined);
  };

  const handleAccountPaymentChange = (payment: AccountPaymentCondition) => {
    setAccountPayment(payment);
  };

  return {
    accountId,
    accountPayment,

    handleAccountIdChange,
    handleAccountPaymentChange,
  };
}
