import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

const RejectSettlementModal = ({
  showRejectCommentModal,
  setShowRejectCommentModal,
  bidId,
}: {
  showRejectCommentModal: boolean;
  setShowRejectCommentModal: Dispatch<SetStateAction<boolean>>;
  bidId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [rejectComment, setRejectComment] = useState("");

  const {
    mutate: confirmSettlement,
    ResponseHandler: ResponseHandlerOfConfirmSettlement,
  } = TRELLO_BID_QUERY.useConfirmSettlement({
    bidId,
  });

  const handleRejectSettlementClick = useCallback(() => {
    confirmSettlement(
      {
        acceptSettlement: false,
        settlementRejectionReason: rejectComment,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("정산 반려를 신청했습니다.");
          return;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          return;
        },
      }
    );
  }, [confirmSettlement, handleSnackbarOpen, rejectComment]);

  return (
    <Dialog
      open={showRejectCommentModal}
      maxWidth="sm"
      fullWidth
      onClose={() => setShowRejectCommentModal(false)}
    >
      <DialogTitle>반려 코멘트 작성</DialogTitle>

      <DialogContent>
        <TextField
          value={rejectComment}
          placeholder="코멘트"
          fullWidth
          onChange={(e) => setRejectComment(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setShowRejectCommentModal(false)}
        >
          돌아가기
        </Button>

        <Button
          className="reject-settlement"
          onClick={handleRejectSettlementClick}
          variant="contained"
          color="primary"
        >
          반려
        </Button>
      </DialogActions>

      {ResponseHandlerOfConfirmSettlement}
    </Dialog>
  );
};

export default RejectSettlementModal;
