import { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";

import { ContainerInfoForAN as Container } from "@sellernote/shared/src/types/forwarding/trello";

import { ContainerFormChangeProps } from "../../../types";

import ContainerTable from "./ContainerTable";
import Header from "./Header";

export default function ContainerInfo({
  containerList,
  onContainerFormChange,
  onContainerAdd,
  onContainerDelete,
  isEditMode,
  isImport,
  setDeletedIdList,
}: {
  containerList: Container[];
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onContainerAdd: () => void;
  onContainerDelete: ({ index }: { index: number }) => void;
  isEditMode: boolean;
  isImport: boolean;
  /** 함수형 업데이트를 위해 Dispatch 타입 사용 */
  setDeletedIdList: Dispatch<SetStateAction<number[]>>;
}) {
  return (
    <Box>
      <Header
        numberOfContainer={containerList.length}
        onContainerAdd={onContainerAdd}
        isEditMode={isEditMode}
      />

      <ContainerTable
        containerList={containerList}
        onContainerFormChange={onContainerFormChange}
        onContainerDelete={onContainerDelete}
        isEditMode={isEditMode}
        isImport={isImport}
        setDeletedIdList={setDeletedIdList}
      />
    </Box>
  );
}
