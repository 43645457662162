import { useCallback, useEffect } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useSetRecoilState } from "recoil";

import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import SHIP_QUERY from "@sellernote/shared/src/queries/forwarding/admin/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import PARTNER_QUERY from "@sellernote/shared/src/queries/forwarding/PARTNER_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/shared/src/states/forwarding/invoice";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/shared/src/types/forwarding/trello";
import { checkIsNull } from "@sellernote/shared/src/utils/common/validation";

import useSetDebuggingDataForIssueReport from "../../../../../../hooks/admin/setDebuggingDataForIssueReport";

import ShipmentCommentList from "../../../TrelloDetail/components/ShipmentCommentList";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "../../../../../../constants/forwarding/adminZIndexLevelRecord";
import BasicInfo from "../../../_components/detail/BasicInfo";
import CargoStatusTable from "../../../_components/detail/CargoStatusTable";
import ContainerInfo from "../../../_components/detail/ContainerInfo";
import FreeTime from "../../../_components/detail/FreeTime";
import Profit from "../../../_components/detail/Profit";
import PurchaseManagement from "../../../_components/detail/PurchaseManagement";
import SalesManagement from "../../../_components/detail/SalesManagement";
import ShipmentSchedule from "../../../_components/detail/ShipmentSchedule";
import ShipNameManagement from "../../../_components/detail/ShipNameManagement";
import TrelloModalExitDialog from "../../../_components/detail/TrelloModalExitDialog";
import UploadDocuments from "../../../_components/detail/UploadDocuments";
import useHandleDialog from "../../../_hooks/useHandleDialog";
import useRefreshTrelloListForDetailModal from "../../../_hooks/useRefreshTrelloListForDetailModal";

const DetailModal = ({
  showsTrelloDetailModal,
  setShowsTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsTrelloDetailModal: boolean;
  setShowsTrelloDetailModal: (value: boolean) => void;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) => {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetTrelloBidDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { refreshTrelloListForDetailModal } =
    useRefreshTrelloListForDetailModal({
      trelloListData,
      fetchTrelloListParams,
      isImport: true,
      trelloDetailData,
    });

  const {
    opensDialog,
    handleDialogClose,
    handleDialogOpen,
    isShipmentScheduleChanged,
  } = useHandleDialog();

  const handleCloseModal = useCallback(() => {
    setShowsTrelloDetailModal(false);

    refreshTrelloListForDetailModal();
  }, [refreshTrelloListForDetailModal, setShowsTrelloDetailModal]);

  const { projectStatus, management } = trelloDetailData ?? {};

  // 출항완료 이후 상태인지 확인. (출항완료 포함)
  const isProjectStatusAfterMoving =
    projectStatus === "moving" ||
    projectStatus === "portEntryAndPrepareCustoms" ||
    projectStatus === "payment" ||
    projectStatus === "completeCustoms" ||
    projectStatus === "delivering" ||
    projectStatus === "finished" ||
    projectStatus === "inSettlement" ||
    projectStatus === "settlementRejected" ||
    projectStatus === "settlementComplete";

  // BL 입력 완료 여부 확인 (mBL 값이 null 이 아닐때, 입력완료)
  const isCompleteBLInput = !checkIsNull(management?.mBL);

  const isFCLOrLCL =
    trelloDetailData?.freightType === "FCL" ||
    trelloDetailData?.freightType === "LCL";

  const { setDebuggingDataForIssueReport } =
    useSetDebuggingDataForIssueReport();

  if (trelloDetailData) {
    setDebuggingDataForIssueReport({
      bidId: trelloDetailData.id.toString(),
      userId: trelloDetailData.userId.toString(),
      userCompany: trelloDetailData.user.company,
    });
  }

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  return (
    <>
      <Dialog
        open={showsTrelloDetailModal}
        maxWidth={false}
        fullWidth
        disableEnforceFocus
        onClose={() => {
          /** 스케줄 변경이 있으면, 모달을 닫을 건지 확인하는 Dialog가 노출 */
          if (isShipmentScheduleChanged) {
            return handleDialogOpen();
          }

          handleCloseModal();
        }}
        sx={{
          zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.drawer - 1,
        }}
      >
        <DialogContent
          sx={{
            height: "100vh",
          }}
        >
          <IconButton
            size="large"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              /** 스케줄 변경이 있으면, 모달을 닫을 건지 확인하는 Dialog가 노출 */
              if (isShipmentScheduleChanged) {
                return handleDialogOpen();
              }

              handleCloseModal();
            }}
          >
            <CloseIcon />
          </IconButton>

          {!trelloDetailData ||
          !shipList ||
          !exchangeRateList ||
          !partnerList ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <BasicInfo
                trelloDetail={trelloDetailData}
                partnerList={partnerList}
              />

              <ShipNameManagement trelloDetail={trelloDetailData} />

              {isFCLOrLCL && <ContainerInfo shipmentId={trelloDetailData.id} />}

              {/* TODO: 정산에서 트렐로 상세 모달을 띄우는 경우, 수입/수출 분기를 트렐로 상세 API 를 호출해야 알 수 있으므로 전체 구조변경이 필요해 임시로 프리타임 부분만 조건 분기를 달아두고 이후 리팩토링 시 분기 개선 */}
              {trelloDetailData.isImport &&
                isProjectStatusAfterMoving &&
                isCompleteBLInput && (
                  <FreeTime shipmentId={trelloDetailData.id} />
                )}

              <ShipmentSchedule trelloDetail={trelloDetailData} />

              <CargoStatusTable trelloDetail={trelloDetailData} />

              <UploadDocuments trelloDetail={trelloDetailData} />

              <SalesManagement trelloDetailData={trelloDetailData} />

              <PurchaseManagement
                trelloDetailData={trelloDetailData}
                partnerList={partnerList}
                exchangeRateList={exchangeRateList}
              />

              <Profit trelloDetail={trelloDetailData} />

              <ShipmentCommentList trelloDetailData={trelloDetailData} />
            </>
          )}
        </DialogContent>
      </Dialog>

      <TrelloModalExitDialog
        opensDialog={opensDialog}
        onDialogClose={handleDialogClose}
        onModalClose={handleCloseModal}
      />
    </>
  );
};

export default DetailModal;
