import { FixedSizeList as List } from "react-window";
import { Box } from "@mui/material";

import { bidExporterProps } from "@sellernote/shared/src/types/forwarding/bidExporter";

import ExporterCard from "./ExporterCard";

interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    list: bidExporterProps[];
  };
}

const ModalContents = ({
  list,
  isImport,
}: {
  list: bidExporterProps[];
  isImport?: boolean;
}) => {
  const Row = ({ index, style, data }: RowProps) => (
    <div style={style}>
      <ExporterCard {...data.list[index]} />
    </div>
  );

  return (
    <Box sx={{ overflowY: "scroll", maxHeight: "450px" }}>
      <List
        width={450}
        height={450}
        itemCount={list.length}
        itemSize={120}
        itemData={{ list: list }}
      >
        {Row}
      </List>
    </Box>
  );
};

export default ModalContents;
