import styled from "styled-components";

import { setFontStyle } from "@sellernote/shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  > .title {
    ${setFontStyle("SubHead2", "Bold")}
    color: #1890ff;
    margin-bottom: 12px;
  }
`;

export default {
  container,
};
