import { useMemo } from "react";
import { Box, Button } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  TrelloBidDetail,
  UNIPASS_CUSTOM_STATUS_ITEM,
} from "@sellernote/shared/src/types/forwarding/trello";

import useHandleCargoManagementUnipassHTMLString from "./hooks";

import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../components/Table";

type CellId = keyof UNIPASS_CUSTOM_STATUS_ITEM | "masterName";

function CargoStatusTable({ trelloDetail }: { trelloDetail: TrelloBidDetail }) {
  const { data: uniPassCargoNumberList } =
    TRELLO_BID_QUERY.useGetUnipassCustomStatusList({ bidId: trelloDetail.id });

  const {
    handleCargoMtNoUpdate,
    ResponseHandlerOfGetCargoManagementUnipassPage,
  } = useHandleCargoManagementUnipassHTMLString();

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "구분",
      },
      {
        id: "cargMtNo",
        disablePadding: false,
        label: "화물관리번호",
      },
      {
        id: "status",
        disablePadding: false,
        label: "통관현황",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!uniPassCargoNumberList) return [];

    return uniPassCargoNumberList.map((v) => {
      const row: TableBodyRow<CellId> = {
        id: v.id,
        cargMtNo: (
          <Button
            sx={{ textDecoration: "underline" }}
            onClick={() => {
              const unipassWindow = window.open("", "Unipass", "_blank");

              if (!unipassWindow) {
                alert("유니패스 페이지를 불러오는 데 실패했습니다.");
                return;
              }

              /** 데이터 로딩 안내 문구 표시. 응답값을 받은 뒤에는 삭제됨 */
              unipassWindow.document.write(
                `<h3 id="fetching-unipass">화물정보 관련 유니패스 데이터를 받아오는 중입니다...</h3>`
              );

              handleCargoMtNoUpdate(v.cargMtNo);
            }}
          >
            {v.cargMtNo}
          </Button>
        ),
        status: v.status,
      };

      return row;
    });
  }, [handleCargoMtNoUpdate, uniPassCargoNumberList]);

  return (
    <>
      <Box sx={{ mb: 2, mt: 2 }}>
        <Table title={"통관 현황"} headCells={headCells} rows={rows} />
      </Box>

      {ResponseHandlerOfGetCargoManagementUnipassPage}
    </>
  );
}

export default CargoStatusTable;
