import { useState } from "react";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/shared/src/jotaiStates/trello";
import { checkEqualObject } from "@sellernote/shared/src/utils/common/etc";

function useHandleDialog() {
  const [opensDialog, setOpensDialog] = useState(false);

  const handleDialogClose = () => setOpensDialog(false);
  const handleDialogOpen = () => setOpensDialog(true);

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const prevShipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.PREVIOUS_SHIPMENT_SCHEDULE_LIST
  );

  /** Mount시 받아온 응답 값과 현재 전역 상태에 담긴 스케줄 정보를 비교 */
  const isShipmentScheduleChanged = !checkEqualObject(
    prevShipmentScheduleList,
    shipmentScheduleList
  );

  return {
    opensDialog,
    handleDialogClose,
    handleDialogOpen,
    isShipmentScheduleChanged,
  };
}

export default useHandleDialog;
