import { Dispatch, SetStateAction, useCallback } from "react";
import { Dialog, DialogContent } from "@mui/material";

import { PartnerListItem } from "@sellernote/shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/shared/src/types/forwarding/trello";

import InlandPartnerForm from "./InlandPartnerForm";

const RegisterInlandPartnerModal = ({
  setShowRegisterInlandInfoModal,
  showRegisterInlandInfoModal,
  trelloDetail,
  partnerList,
  bidAccountPayableId,
}: {
  setShowRegisterInlandInfoModal: Dispatch<SetStateAction<boolean>>;
  showRegisterInlandInfoModal: boolean;
  trelloDetail: TrelloBidDetail;
  partnerList?: PartnerListItem[];
  bidAccountPayableId: number;
}) => {
  const handleRegisterInlandInfoModalCancel = useCallback(() => {
    setShowRegisterInlandInfoModal(false);
  }, [setShowRegisterInlandInfoModal]);

  return (
    <Dialog
      open={showRegisterInlandInfoModal}
      maxWidth="xs"
      fullWidth
      onClose={handleRegisterInlandInfoModalCancel}
    >
      <DialogContent>
        {bidAccountPayableId && (
          <InlandPartnerForm
            trelloDetail={trelloDetail}
            partnerList={partnerList}
            bidAccountPayableId={bidAccountPayableId}
            onClose={handleRegisterInlandInfoModalCancel}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterInlandPartnerModal;
