import { ChangeEvent, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Box, Button, Switch, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/shared/src/constants";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { PartnerListItem } from "@sellernote/shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/shared/src/types/forwarding/trello";
import { changeBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import {
  changeInlandTypeToNameKr,
  checkIfHaveAuthorityToChangeTrelloDetail,
  getInlandPartnerName,
  getTrelloBidEndPortNameAndDestination,
  getTrelloBidStartPortNameAndDestination,
  returnStartAddress,
} from "@sellernote/shared/src/utils/forwarding/admin/trello";

import AdminDetailDescription from "../../../../../components/AdminDetailDescription";

import AddMemoModal from "./AddMemoModal";
import EndInland from "./EndInland";
import RegisterInlandPartnerModal from "./RegisterInlandPartnerModal";
import TrackingShipModal from "./TrackingShipModal";

function BasicDescription({
  trelloDetail,
  partnerList,
}: {
  trelloDetail: TrelloBidDetail;
  partnerList?: PartnerListItem[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const queryClient = useQueryClient();

  const [showRegisterInlandInfoModal, setShowRegisterInlandInfoModal] =
    useState(false);
  const [showsMemoModal, setShowsMemoModal] = useState(false);
  const [memo, setMemo] = useState<string>("");
  const [showsTrackingShipModal, setShowsTrackingShipModal] = useState(false);

  const { mutate: addMemo } = TRELLO_BID_QUERY.useAddTrelloMemo({
    bidId: trelloDetail.id,

    onSuccess: () => {
      handleSnackbarOpen("메모를 등록했습니다.");
      setMemo("");
      setShowsMemoModal(false);
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      return;
    },
  });

  const {
    mutate: sendInlandMail,
    ResponseHandler: ResponseHandlerOfUseSendInlandMail,
  } = TRELLO_BID_QUERY.useSendInlandMail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },
      customizeMessage: () => ({
        title: "메일을 발송했습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const {
    mutate: changeFTACertificateFlag,
    ResponseHandler: ResponseHandlerOfUseChangeFTACertificateFlag,
  } = TRELLO_BID_QUERY.useChangeFTACertificateFlag(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
    customizeMessage: () => ({
      title: "FTA C/O를 변경했습니다.",
    }),
  });

  const handleFTACertificateChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeFTACertificateFlag({ FTACertificateFlag: e.target.checked });
  };

  const handleInlandMailSend = () => {
    sendInlandMail({});
  };

  const buttonDisabledValue =
    APP_NAME !== "shipda-admin" ||
    checkIfHaveAuthorityToChangeTrelloDetail(
      currentAdminAuthInfo?.authority,
      trelloDetail.projectStatus
    );

  const bidAccountPayableId = useMemo(() => {
    const result = trelloDetail.accountPayables.find(
      (v) => v.domain === "inland" || v.domain === "parcel"
    );

    return result?.id;
  }, [trelloDetail.accountPayables]);

  const handleTrackingShipModalOpen = () => {
    setShowsTrackingShipModal(true);
  };

  if (APP_NAME === "partner-admin") {
    return (
      <>
        <AdminDetailDescription
          title={"기본정보"}
          descriptionValueList={[
            {
              label: "회사명(팀명)",
              value: `${trelloDetail.team.company || "-"}(${
                trelloDetail.team.name ?? "-"
              })`,
            },
            {
              label: "사업자등록번호",
              value: trelloDetail.team.BRN,
              gridSize: 9,
            },
            {
              label: "담당자 이름",
              value: trelloDetail.user.name,
            },
            {
              label: "연락처",
              value: trelloDetail.user.phone,
            },
            {
              label: "이메일",
              value: trelloDetail.user.email,
              gridSize: 6,
            },
            {
              label: "출발지 내륙",
              value: returnStartAddress(trelloDetail),
            },
            {
              label: "출발지 항구/공항",
              value: getTrelloBidStartPortNameAndDestination(trelloDetail),
            },
            {
              label: "도착지 항구/공항",
              value: getTrelloBidEndPortNameAndDestination(trelloDetail),
            },
          ]}
        />
      </>
    );
  }

  if (!trelloDetail.isImport) {
    return (
      <>
        <AdminDetailDescription
          title={"기본정보"}
          descriptionValueList={[
            {
              label: "회사명(팀명)",
              value: `${trelloDetail.team.company || "-"}(${
                trelloDetail.team.name ?? "-"
              })`,
            },
            {
              label: "사업자등록번호",
              value: trelloDetail.team.BRN,
              gridSize: 9,
            },
            {
              label: "담당자 이름",
              value: trelloDetail.user.name,
            },
            {
              label: "연락처",
              value: trelloDetail.user.phone,
            },
            {
              label: "이메일",
              value: trelloDetail.user.email,
              gridSize: 6,
            },
            {
              label: "출발지 내륙",
              value: returnStartAddress(trelloDetail),
            },
            {
              label: "출발지 항구/공항",
              value: (
                <Button variant="text" onClick={handleTrackingShipModalOpen}>
                  {getTrelloBidStartPortNameAndDestination(trelloDetail)}
                </Button>
              ),
            },
            {
              label: "도착지 항구/공항",
              value: (
                <Button variant="text" onClick={handleTrackingShipModalOpen}>
                  {getTrelloBidEndPortNameAndDestination(trelloDetail)}
                </Button>
              ),
            },
            {
              label: "도착지 내륙",
              value: trelloDetail.endAddress,
            },
            {
              label: "수출통관",
              value: trelloDetail.containExportCustoms ? "O" : "X",
            },
            {
              label: "VGM",
              value: trelloDetail.useVGM ? "O" : "X",
            },
            {
              label: "적하보험",
              value: trelloDetail.hopeCargoInsurance ? "O" : "X",
            },
            {
              label: "수입통관",
              value: trelloDetail.containCustoms ? "O" : "X",
            },
            {
              label: "출발지 컨테이너 진입가능",
              value: changeBooleanValueToKr(
                trelloDetail.containerStuffing,
                true
              ),
            },

            {
              label: "내륙운송 유형",
              value: (
                <Box>{changeInlandTypeToNameKr(trelloDetail.originInland)}</Box>
              ),
            },
            {
              label: "메모",
              value: (
                <Box>
                  <Typography variant="body2">
                    {trelloDetail.management.memo ?? ""}
                  </Typography>

                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ padding: 0 }}
                    onClick={() => {
                      setShowsMemoModal(true);
                    }}
                  >
                    입력
                  </Button>
                </Box>
              ),
            },
          ]}
        />

        {showsMemoModal && (
          <AddMemoModal
            currentMemo={trelloDetail.management.memo ?? ""}
            addMemo={addMemo}
            memo={memo}
            setMemo={setMemo}
            showsMemoModal={showsMemoModal}
            setShowsMemoModal={setShowsMemoModal}
          />
        )}

        <TrackingShipModal
          showsTrackingShipModal={showsTrackingShipModal}
          setShowsTrackingShipModal={setShowsTrackingShipModal}
          trelloId={trelloDetail.id}
          freightType={trelloDetail.freightType}
        />
      </>
    );
  }

  return (
    <>
      <AdminDetailDescription
        title={"기본정보"}
        descriptionValueList={[
          {
            label: "회사명(팀명)",
            value: `${trelloDetail.team.company || "-"}(${
              trelloDetail.team.name ?? "-"
            })`,
          },
          {
            label: "사업자등록번호",
            value: trelloDetail.team.BRN,
            gridSize: 9,
          },
          {
            label: "담당자 이름",
            value: trelloDetail.user.name,
          },
          {
            label: "연락처",
            value: trelloDetail.user.phone,
          },
          {
            label: "이메일",
            value: trelloDetail.user.email,
            gridSize: 6,
          },
          {
            label: "출발지 내륙",
            value: returnStartAddress(trelloDetail),
          },
          {
            label: "출발지 항구/공항",
            value: (
              <Button variant="text" onClick={handleTrackingShipModalOpen}>
                {getTrelloBidStartPortNameAndDestination(trelloDetail)}
              </Button>
            ),
          },
          {
            label: "도착지 항구/공항",
            value: (
              <Button variant="text" onClick={handleTrackingShipModalOpen}>
                {getTrelloBidEndPortNameAndDestination(trelloDetail)}
              </Button>
            ),
          },
          {
            label: "도착지 내륙",
            value: (
              <EndInland
                trelloDetail={trelloDetail}
                bidAccountPayableId={bidAccountPayableId}
              />
            ),
          },
          {
            label: "수입통관",
            value: trelloDetail.containCustoms ? "O" : "X",
          },
          {
            label: "적하보험",
            value: trelloDetail.hopeCargoInsurance ? "O" : "X",
          },
          {
            label: "FTA C/O 대행",
            value: trelloDetail.needFTACertificateAgency ? "O" : "X",
          },
          {
            label: "컨테이너 적출여부",
            value: trelloDetail.containerAccessable ? "X" : "O",
          },
          {
            label: "내륙운송 유형",
            value: (
              <Box>
                {changeInlandTypeToNameKr(trelloDetail.inlandType)}

                {!getInlandPartnerName(trelloDetail.accountPayables) ? (
                  <Button
                    disabled={!trelloDetail.endAddress || buttonDisabledValue}
                    onClick={() => {
                      if (!bidAccountPayableId) {
                        handleSnackbarOpen(
                          "내륙운송 매입항목을 추가해주세요.",
                          "error"
                        );
                        return;
                      }

                      setShowRegisterInlandInfoModal(true);
                    }}
                  >
                    입력
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={buttonDisabledValue}
                      onClick={() => {
                        if (!bidAccountPayableId) {
                          handleSnackbarOpen(
                            "내륙운송 매입항목을 추가해주세요.",
                            "error"
                          );
                          return;
                        }

                        setShowRegisterInlandInfoModal(true);
                      }}
                    >
                      수정
                    </Button>

                    <Button
                      disabled={buttonDisabledValue}
                      onClick={handleInlandMailSend}
                    >
                      메일 발송
                    </Button>
                  </>
                )}
              </Box>
            ),
          },
          {
            label: "FTA C/O 적용",
            value: (
              <Switch
                onChange={handleFTACertificateChange}
                defaultChecked={trelloDetail.FTACertificateFlag}
              />
            ),
          },

          {
            label: "메모",
            value: (
              <Box>
                <Typography variant="body2">
                  {trelloDetail.management.memo ?? ""}
                </Typography>

                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 0 }}
                  onClick={() => {
                    setShowsMemoModal(true);
                  }}
                >
                  입력
                </Button>
              </Box>
            ),
          },
        ]}
      />

      {bidAccountPayableId && showRegisterInlandInfoModal && (
        <RegisterInlandPartnerModal
          bidAccountPayableId={bidAccountPayableId}
          partnerList={partnerList}
          showRegisterInlandInfoModal={showRegisterInlandInfoModal}
          trelloDetail={trelloDetail}
          setShowRegisterInlandInfoModal={setShowRegisterInlandInfoModal}
        />
      )}

      {showsMemoModal && (
        <AddMemoModal
          currentMemo={trelloDetail.management.memo ?? ""}
          addMemo={addMemo}
          memo={memo}
          setMemo={setMemo}
          showsMemoModal={showsMemoModal}
          setShowsMemoModal={setShowsMemoModal}
        />
      )}

      <TrackingShipModal
        showsTrackingShipModal={showsTrackingShipModal}
        setShowsTrackingShipModal={setShowsTrackingShipModal}
        trelloId={trelloDetail.id}
        freightType={trelloDetail.freightType}
      />

      {ResponseHandlerOfUseSendInlandMail}
      {ResponseHandlerOfUseChangeFTACertificateFlag}
    </>
  );
}

export default BasicDescription;
