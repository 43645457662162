import { Box, SxProps, Theme, Typography } from "@mui/material";

import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import { getCustomsPartnerName } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";
import { changeInlandTypeToNameKr } from "@sellernote/shared/src/utils/forwarding/admin/trello";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-start",
  gap: "4px",
  "> p:first-of-type": {
    color: COLOR.bk_60,
  },
};

const boxGridStyle: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "4px",
};

const boxGridCellStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
};

const CheckDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const { isImport, freightType } = bidDetail;

  const changeCheckpointValueToKr = (value: boolean | null) => {
    switch (value) {
      case true:
        return "포함";
      case false:
        return "미포함";
      default:
        return "-";
    }
  };

  const changeContainerAccessableValueToKr = (value: boolean | null) => {
    switch (value) {
      case true:
        return "가능";
      case false:
        return "불가능";
      default:
        return "-";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={boxGridStyle}>
        {bidDetail.isImport && (
          <Box sx={boxGridCellStyle}>
            <Typography variant="subtitle1" className="sads">
              비용 관련 사항
            </Typography>

            {bidDetail.serviceType !== "consolidation" && (
              <>
                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">국내부대비용:</Typography>

                  <Typography className="sads">
                    {changeCheckpointValueToKr(bidDetail.containDomesticFee)}
                  </Typography>
                </Box>

                {bidDetail.freightType !== "AIR" && (
                  <Box sx={boxLayoutStyle}>
                    <Typography className="sads">Ocean Surcharge:</Typography>

                    <Typography className="sads">
                      {changeCheckpointValueToKr(
                        bidDetail.containOceanSurcharge
                      )}
                    </Typography>
                  </Box>
                )}
              </>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography className="sads">LSS 납부:</Typography>

              <Typography className="sads">
                {changeCheckpointValueToKr(bidDetail.containLss)}
              </Typography>
            </Box>
          </Box>
        )}

        {((bidDetail.isImport && bidDetail.endType === "inland") ||
          (!bidDetail.isImport && bidDetail.startType === "inland")) && (
          <Box sx={boxGridCellStyle}>
            <Typography variant="subtitle1" className="sads">
              내륙 운송
            </Typography>

            {isImport ? (
              freightType === "FCL" ? (
                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">
                    도착지 컨테이너 진입여부:
                  </Typography>

                  <Typography className="sads">
                    {changeContainerAccessableValueToKr(
                      bidDetail.containerAccessable
                    )}
                  </Typography>
                </Box>
              ) : (
                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">도착지 운송방식:</Typography>

                  <Box>
                    <Typography className="sads">
                      {changeInlandTypeToNameKr(bidDetail.inlandType)}
                    </Typography>
                  </Box>
                </Box>
              )
            ) : freightType === "FCL" ? (
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">
                  출발지 컨테이너 진입여부:
                </Typography>

                <Box>
                  <Typography className="sads">
                    {changeBooleanValueToKr(bidDetail.containerAccessable)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">출발지 운송방식:</Typography>

                <Box>
                  <Typography className="sads">
                    {changeInlandTypeToNameKr(bidDetail.originInland)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}

        <Box sx={boxGridCellStyle}>
          <Typography variant="subtitle1" className="sads">
            부가서비스
          </Typography>

          {bidDetail.isImport ? (
            <>
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">수입통관업무 대행:</Typography>

                <Typography className="sads">
                  {changeCheckpointValueToKr(bidDetail.containCustoms)}
                  {!bidDetail.containCustoms &&
                    ` (${getCustomsPartnerName(bidDetail.accountPayables)})`}
                </Typography>
              </Box>

              <Box sx={boxLayoutStyle}>
                <Typography className="sads">
                  FTA 원산지증명서 대행발급:
                </Typography>

                <Typography className="sads">
                  {changeCheckpointValueToKr(
                    bidDetail.needFTACertificateAgency
                  )}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">수출통관업무 대행:</Typography>

                <Box>
                  <Typography className="sads">
                    {changeCheckpointValueToKr(bidDetail.containExportCustoms)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={boxLayoutStyle}>
                <Typography className="sads">수입통관업무 대행:</Typography>

                <Box>
                  <Typography className="sads">
                    {changeCheckpointValueToKr(bidDetail.containCustoms)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={boxLayoutStyle}>
                <Typography className="sads">VGM:</Typography>

                <Box>
                  <Typography className="sads">
                    {bidDetail.useVGM
                      ? "포함"
                      : bidDetail.freightType === "FCL"
                      ? "미포함"
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          <Box sx={boxLayoutStyle}>
            <Typography className="sads">적하보험 가입:</Typography>

            <Typography className="sads">
              {changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ ...boxGridCellStyle, gridColumn: "span 2" }}>
          <Typography variant="subtitle1" className="sads">
            요청사항
          </Typography>

          <Typography className="sads" sx={{ whiteSpace: "pre-line" }}>
            {bidDetail.userNote || "-"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckDesc;
