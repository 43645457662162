import { Box, SxProps, Theme, Typography } from "@mui/material";

import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { getLabelForCarrier } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

export default function ScheduleDesc({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) {
  return (
    <Box>
      <Typography variant="h6">스케줄</Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography>{getLabelForCarrier(bidDetail.freightType)}:</Typography>
          <Typography>
            {bidDetail.freightType === "FCL"
              ? bidDetail.management?.liner?.name || "-"
              : bidDetail.management?.partnerCompanyName || "-"}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>ETD:</Typography>
          <Typography>
            {formatDate({
              date: bidDetail.management.fullETD,
              type: "YY_MM_DD",
            }) || "-"}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>ETA:</Typography>
          <Typography>
            {formatDate({
              date: bidDetail.management.fullETA,
              type: "YY_MM_DD",
            }) || "-"}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>
            {bidDetail.freightType === "AIR" ? "항공사명" : "모선명"}:
          </Typography>
          <Typography>
            {bidDetail.freightType === "AIR"
              ? bidDetail.management.liner?.name || "-"
              : bidDetail.management.shipName || "-"}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>
            {bidDetail.freightType === "AIR" ? "편명" : "항차"}:
          </Typography>
          <Typography>{bidDetail.management.voyageNumber || "-"}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
