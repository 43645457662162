import { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import UploadModal from "@sellernote/shared/src/admin-ui/containers/UploadModal";
import { APP_NAME } from "@sellernote/shared/src/constants";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

export default function ShipLetter({ shipmentId }: { shipmentId: number }) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsUploadModal, setShowsUploadModal] = useState(false);

  const { data: attachments } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: shipmentId,
  });

  const { mutate: deleteFile, ResponseHandler: ResponseHandlerOfDeleteFile } =
    ADMIN_FILE_QUERY.useDeleteFile({});

  const { refetch: refetchGetAttachments } =
    TRELLO_BID_QUERY.useGetTrelloAttachments({
      bidId: shipmentId,
    });

  /**
   * 응답값에 공문이 있는지 확인
   */
  const shipLetterAttachment = useMemo(() => {
    return attachments?.find(
      (attachment) => attachment.domain === "shipLetter"
    );
  }, [attachments]);

  const handleFileDelete = () => {
    if (!shipLetterAttachment) return;

    deleteFile(
      {
        attachmentId: shipLetterAttachment.id,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("삭제되었습니다.");
          refetchGetAttachments();
        },

        onError: () => {
          handleSnackbarOpen("삭제에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Box display={"flex"} alignItems={"center"} gap={"10px"} sx={{ mr: 3 }}>
        <Typography variant="button">공문: </Typography>

        {shipLetterAttachment ? (
          <>
            <Typography variant="button">
              {shipLetterAttachment.name}
            </Typography>

            <Button variant="outlined" onClick={handleFileDelete}>
              삭제
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            disabled={APP_NAME === "partner-admin"}
            onClick={() => setShowsUploadModal(true)}
          >
            업로드
          </Button>
        )}
      </Box>

      <UploadModal
        setShowsUploadModal={setShowsUploadModal}
        showsUploadModal={showsUploadModal}
        type={"shipLetter"}
        id={shipmentId}
        dataType={"trello"}
        isPermission
        isMultiple={false}
        acceptedFileTypes={["jpeg", "jpg", "pdf", "png"]}
      />

      {ResponseHandlerOfDeleteFile}
    </>
  );
}
