import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

function useHandleOpCheckPoint({ shipmentId }: { shipmentId: number }) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    mutate: updateCheckPoint,
    ResponseHandler: ResponseHandlerOfUpdateOpCheckPoint,
  } = TRELLO_BID_QUERY.useUpdateOpCheckPoint();

  const handleCheckPointUpdate = (BLType: string) => {
    updateCheckPoint(
      {
        typeBL: BLType,
        pathParams: {
          shipmentId,
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("BL 유형 변경을 완료했습니다.");

          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
              bidId: shipmentId,
            })
          );
        },
      }
    );
  };

  return {
    handleCheckPointUpdate,
    ResponseHandlerOfUpdateOpCheckPoint,
  };
}

export default useHandleOpCheckPoint;
