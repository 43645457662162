import { useContext, useState } from "react";
import { Box } from "@mui/material";

import { FreightType } from "@sellernote/shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/shared/src/types/forwarding/bid";
import { ScheduleModifierType } from "@sellernote/shared/src/types/forwarding/trello";

import useCheckFormValidBeforeSave from "./hooks/useCheckFormValidBeforeSave";
import useHandleRegisterSchedule from "./hooks/useHandleRegisterSchedule";
import useHandleScheduleMail from "./hooks/useHandleScheduleMail";
import useHandleUpdateSchedule from "./hooks/useHandleUpdateSchedule";

import Modal from "../../../../../../../../components/Modal";
import SaveButtonGroup from "../../components/SaveButtonGroup";

import { FormDisabledContext } from "../..";
import ScheduleChangeReasonModalBody from "./ScheduleChangeReasonModalBody";
import ScheduleRegisterConfirmModalBody from "./ScheduleRegisterConfirmModalBody";
import ShipLetter from "./ShipLetter";

export default function SaveAndEmailButton({
  freightType,
  highlightsButton,
  shipmentId,
  isFirstScheduleEmailSend,
  scheduleModifierType,
  scheduleMailAt,
  projectStatus,
  isImport,
  changeReasonByAdmin,
  isScheduleChangedByAdmin,
}: {
  freightType: FreightType;
  highlightsButton: boolean;
  shipmentId: number;
  isFirstScheduleEmailSend: boolean;
  scheduleModifierType: ScheduleModifierType | undefined;
  scheduleMailAt: Date | null | undefined;
  projectStatus: BidProjectStatus;
  isImport: boolean;
  changeReasonByAdmin: string | null;
  isScheduleChangedByAdmin: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  /** SeaRates나 선사에의해 스케줄이 변경됐는지 확인하는 flag */
  const isScheduleModifiedBySeaRatesOrLiner =
    scheduleModifierType === "liner" || scheduleModifierType === "sr";

  /** 스케줄 변경 이유 */
  const [scheduleChangeReason, setScheduleChangeReason] = useState("");

  const {
    handleScheduleRegister,
    opensRegisterScheduleModal,
    setOpensRegisterScheduleModal,
  } = useHandleRegisterSchedule({
    isFirstScheduleEmailSend,
    projectStatus,
    isImport,
    shipmentId,
  });

  const {
    showsScheduleChangeReasonModal,
    setShowsScheduleChangeReasonModal,
    stopsAutoRenewal,
    setStopsAutoRenewal,
    sendsEmail,
    setSendsEmail,
    handleScheduleUpdate,
  } = useHandleUpdateSchedule({
    shipmentId,
    scheduleChangeReason,
    setScheduleChangeReason,
    isScheduleModifiedBySeaRatesOrLiner,
  });

  const { validateFormAndSave } = useCheckFormValidBeforeSave({
    shipmentId,
    freightType,
    setShowsScheduleChangeReasonModal,
    setOpensRegisterScheduleModal,
  });

  const {
    opensModalForMail,
    setOpensMailForMail,
    handleScheduleMailSend,
    handleScheduleMailSendWithReason,
  } = useHandleScheduleMail({
    shipmentId,
    changeReasonByAdmin,
    scheduleChangeReason,
    setScheduleChangeReason,
    isScheduleChangedByAdmin,
  });

  const handleModalClose = () => {
    setOpensMailForMail(false);
    setShowsScheduleChangeReasonModal(false);
  };

  return (
    <Box display={"flex"} justifyContent={"flex-end"}>
      <ShipLetter shipmentId={shipmentId} />

      <SaveButtonGroup
        saveButton={{
          onClick: () => {
            validateFormAndSave({
              opensScheduleReasonModal: isFirstScheduleEmailSend,
            });
          },
          /** 어드민이 스케줄을 변경했을 때 저장 버튼을 활성화 */
          isDisabled: !isScheduleChangedByAdmin,
        }}
        saveAndEmailButton={{
          onClick: handleScheduleMailSend,
          showsAnimation: highlightsButton,
          isDisabled: isDisabled || Boolean(scheduleMailAt),
          isOnlySendEmail: true,
          /** 스케줄 입력은 메일발송 버튼을 노출하지 않음(무조건 보내게 BE에서 처리돼있음) */
          hidesButton: !isFirstScheduleEmailSend,
        }}
      />

      <Modal
        isOpened={showsScheduleChangeReasonModal || opensModalForMail}
        handleClose={handleModalClose}
        modalBody={
          <ScheduleChangeReasonModalBody
            isScheduleModifiedBySeaRatesOrLiner={
              isScheduleModifiedBySeaRatesOrLiner
            }
            stopsAutoRenewal={stopsAutoRenewal}
            setStopsAutoRenewal={setStopsAutoRenewal}
            sendsEmail={sendsEmail}
            setSendsEmail={setSendsEmail}
            scheduleChangeReason={scheduleChangeReason}
            setScheduleChangeReason={setScheduleChangeReason}
            opensModalForMail={opensModalForMail}
            onClick={
              opensModalForMail
                ? handleScheduleMailSendWithReason
                : handleScheduleUpdate
            }
          />
        }
      />

      <Modal
        isOpened={opensRegisterScheduleModal}
        handleClose={() => setOpensRegisterScheduleModal(false)}
        modalBody={
          <ScheduleRegisterConfirmModalBody
            onScheduleRegister={handleScheduleRegister}
          />
        }
      />
    </Box>
  );
}
