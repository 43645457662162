import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";

import { APP_NAME } from "@sellernote/shared/src/constants";
import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  "> p:first-of-type": {
    color: COLOR.bk_60,
  },
};

const isShipdaAdmin = APP_NAME === "shipda-admin";

const BasicDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const history = useHistory();

  const handleMoveToUserDetailPageClick = useCallback(() => {
    if (bidDetail.team.isPrivate) {
      history.push(`/users/list/${bidDetail.userId}`);
    } else {
      history.push(`/team/${bidDetail.team.id}`);
    }
  }, [bidDetail.team.id, bidDetail.team.isPrivate, bidDetail.userId, history]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        rowGap: "8px",
      }}
    >
      <Box sx={boxLayoutStyle}>
        <Typography className="sads">회사명:</Typography>

        {isShipdaAdmin && bidDetail.team.company ? (
          <Button
            variant="text"
            className="sads"
            sx={{ userSelect: "text" }}
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.company}
          </Button>
        ) : (
          <Typography className="sads">
            {bidDetail.team.company ?? "-"}
          </Typography>
        )}
      </Box>

      <Box sx={{ ...boxLayoutStyle, gridColumn: "span 2" }}>
        <Typography className="sads">팀명:</Typography>

        {isShipdaAdmin && bidDetail.team.name ? (
          <Button
            variant="text"
            className="sads"
            sx={{ userSelect: "text" }}
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.name}
          </Button>
        ) : (
          <Typography className="sads">{bidDetail.team.name ?? "-"}</Typography>
        )}
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography className="sads">담당자 이름:</Typography>

        <Typography className="sads">{bidDetail.user.name}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography className="sads">연락처:</Typography>

        <Typography className="sads">{bidDetail.user.phone}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography className="sads">이메일:</Typography>

        <Typography className="sads">{bidDetail.user.email}</Typography>
      </Box>
    </Box>
  );
};

export default BasicDesc;
