import {
  GET_TRELLO_BID_FINISHED_LIST_REQ,
  GET_TRELLO_BID_LIST_REQ,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/trello";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { BidServiceType } from "@sellernote/shared/src/types/forwarding/bid";
import { FetchTrelloListParams } from "@sellernote/shared/src/types/forwarding/trello";

/**
 * trello의 react-query key들을 리턴하는 커스텀 훅
 */
function useGetTrelloQueryKeyGen({
  fetchTrelloListParams,
  serviceType,
}: {
  fetchTrelloListParams?: FetchTrelloListParams;
  serviceType: Exclude<BidServiceType, "oceanTicket"> | undefined;
}) {
  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams || {};

  /** 진행중과 종료 리스트 요청에 공통으로 사용하는 params */
  const commonListFetchParams: Omit<
    GET_TRELLO_BID_LIST_REQ,
    "status" | "perPage"
  > = {
    ...trelloParams,
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    serviceType: serviceType === "consolidation" ? "consolidation" : undefined,
  };

  const trelloListParams: GET_TRELLO_BID_LIST_REQ = {
    ...commonListFetchParams,
    status: "inProgress",
    perPage: 300,
  };

  const finishedTrelloListParams: GET_TRELLO_BID_FINISHED_LIST_REQ = {
    ...commonListFetchParams,
    status: "finished",
    projectStatus: "finished",
    perPage: 200,
  };

  const importationTrelloListQueryKey =
    TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
      ...trelloListParams,
    });

  const exportationTrelloListQueryKey =
    TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
      ...trelloListParams,
    });

  const importationTrelloFinishedListQueryKey =
    TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList({
      ...finishedTrelloListParams,
    });

  const exportationTrelloFinishedListQueryKey =
    TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList({
      ...finishedTrelloListParams,
    });

  return {
    importationTrelloListQueryKey,
    exportationTrelloListQueryKey,
    importationTrelloFinishedListQueryKey,
    exportationTrelloFinishedListQueryKey,
  };
}

export default useGetTrelloQueryKeyGen;
