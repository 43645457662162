import { useContext } from "react";
import { useQueryClient } from "react-query";
import { Box, Switch, Typography } from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import { FormDisabledContext } from "..";

export default function AutoRenewalSwitch({
  isAutoUpdateActivated,
  shipmentId,
}: {
  isAutoUpdateActivated: boolean | null | undefined;
  shipmentId: number;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const isDisabled = useContext(FormDisabledContext);

  const { mutate: updateAutoTracking } = TRELLO_BID_QUERY.useUpdateAutoTracking(
    { shipmentId }
  );

  const handleSuccess = () => {
    handleSnackbarOpen("자동 갱신 여부가 정상적으로 반영됐습니다.", "success");

    queryClient.invalidateQueries(
      TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({
        shipmentId,
      })
    );
  };

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography>자동 갱신</Typography>

      <Switch
        checked={Boolean(isAutoUpdateActivated)}
        disabled={isDisabled || isAutoUpdateActivated === null}
        onChange={(_, v: boolean) => {
          updateAutoTracking({ activate: v }, { onSuccess: handleSuccess });
        }}
      />
    </Box>
  );
}
