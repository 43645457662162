import { useEffect, useState } from "react";
import { UseFormReset } from "react-hook-form";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  ParsedWithdrawalDetail,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/shared/src/types/forwarding/trello";

const EMPTY_PARSED_FORM_LIST_MESSAGE =
  "파일에서 읽어온 정보가 없습니다. 수동으로 기입해주세요.";

/**
 * OCR을 사용한 매입 파일 읽기 데이터 조회 및 매입 항목 초기화 확인 모달 관리 훅
 * @param reset - RHF의 reset 함수
 */
export const useGetParsedWithdrawalFormData = ({
  reset,
  applyCurrency,
}: {
  reset: UseFormReset<WithdrawalFormListData>;
  applyCurrency: (list: WithdrawalFormDetail[]) => void;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [parsedList, setParsedList] = useState<ParsedWithdrawalDetail[]>([]);

  const [attachmentId, setAttachmentId] = useState<number | undefined>(
    undefined
  );
  const [showsOverlayConfirmModal, setShowsOverlayConfirmModal] =
    useState(false);

  const handleOverlayConfirmModalOpen = ({ id }: { id: number }) => {
    setAttachmentId(id);
    setShowsOverlayConfirmModal(true);
  };

  const {
    mutate: getParsedWithdrawalFormList,
    isLoading: isLoadingGettingParsedWithdrawalForm,
    isSuccess: isSuccessGettingParsedWithdrawalForm,
    ResponseHandler: ResponseHandlerOfGetParsedWithdrawalFormList,
  } = TRELLO_BID_QUERY.useGetParsedWithdrawalFormList({
    onSuccess: ({ detailList }) => {
      if (!detailList) return;
      handleSnackbarOpen("파일 읽기에 성공했습니다.");
      setParsedList(detailList);
    },
    onError: () => {
      handleSnackbarOpen("파일 읽기에 실패했습니다.", "error");
    },
  });

  /** RHF에서 reset 함수는 useEffect 내부에서 호출을 권장함 */
  useEffect(() => {
    if (!isSuccessGettingParsedWithdrawalForm) return;
    if (!parsedList) return;

    /** 파싱한 데이터가 없을 땐 reset을 진행하지 않음 */
    if (!parsedList.length) {
      handleSnackbarOpen(EMPTY_PARSED_FORM_LIST_MESSAGE, "warning");
      return;
    }

    /** ID를 추가해 기존 매입 입력 항목 데이터와 구조를 맞춤 */
    const withdrawalFormList: WithdrawalFormDetail[] = parsedList.map(
      (form, index) => {
        return {
          id: index,
          amount: form.amount,
          currency: form.currency,
          finalPrice: form.finalPrice,
          isVAT: form.isVAT,
          itemUnitMeasurement: form.itemUnitMeasurement,
          name: form.name,
          note: form.note,
          totalPrice: form.totalPrice,
          vatPrice: form.vatPrice,
          unitPrice: form.unitPrice,
        };
      }
    );

    applyCurrency(withdrawalFormList);
    /** 파싱에 성공했을 때만 useEffect를 호출 */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessGettingParsedWithdrawalForm, parsedList]);

  const getParsedList = (attachmentId: number) => {
    getParsedWithdrawalFormList({ pathParams: { attachmentId } });
  };

  const handleParsedWithdrawalFormGet = () => {
    if (!attachmentId) return;

    getParsedList(attachmentId);
    setShowsOverlayConfirmModal(false);
  };

  return {
    showsOverlayConfirmModal,
    setShowsOverlayConfirmModal,
    handleOverlayConfirmModalOpen,
    getParsedList,
    handleParsedWithdrawalFormGet,
    isLoadingGettingParsedWithdrawalForm,
    ResponseHandler: <>{ResponseHandlerOfGetParsedWithdrawalFormList}</>,
  };
};
