import { useMemo } from "react";
import { Box } from "@mui/material";

import {
  ExportTrelloList,
  TrelloCardRenewOpCheckPointProps,
  TrelloIssueFilter,
} from "@sellernote/shared/src/types/forwarding/trello";

import { getTrelloCardTitle } from "../utils";
import {
  checkScheduleUpdate,
  checkStatusUpdate,
  filterByTrelloCardIssue,
  sortByShipmentScheduleDateDesc,
} from "../utils";

import TrelloCardCheckBox from "../_components/list/TrelloCardCheckBox";
import TrelloCardCommonBody from "../_components/list/TrelloCardCommonBody";
import { CardListData } from "../_components/list/TrelloCardList";

// TODO: dev API 및 인터페이스 정리가 안된 상태 배포되면 추가 작업
export default function useExportTrelloList({
  trelloListData,
  handleTrelloCardClick,
  handleTrelloCardOpCheckPoint,
  sessionStorageTrelloIssueFilter,
}: {
  trelloListData: ExportTrelloList[];
  handleTrelloCardClick: (cardData: ExportTrelloList) => void;
  handleTrelloCardOpCheckPoint: ({
    shipmentId,
    isChecked,
    key,
    isFinished,
    isImport,
  }: TrelloCardRenewOpCheckPointProps) => void;
  sessionStorageTrelloIssueFilter: TrelloIssueFilter | null;
}) {
  const exportCardListData: CardListData[] = useMemo(() => {
    return [
      {
        lineKey: "1",
        lineTitle: "화물 준비 중",
        cards: trelloListData
          .filter((v) => v.projectStatus === "waitingFreight")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETD"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 발행여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "2",
        lineTitle: "출항 준비 중",
        cards: trelloListData
          .filter(
            (v) =>
              v.projectStatus === "scheduling" ||
              v.projectStatus === "containerCarryOut" ||
              v.projectStatus === "containerPickup" ||
              v.projectStatus === "gateIn" ||
              v.projectStatus === "loaded" ||
              v.projectStatus === "domesticImportCFS"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETD"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              projectStatus: v.projectStatus,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <TrelloCardCheckBox
                    label="수출신고수리"
                    checkedValue={false}
                  />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: false,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 발행여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "3",
        lineTitle: "출항 완료",
        cards: trelloListData
          .filter((v) => v.projectStatus === "moving")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETA"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {
                    <TrelloCardCheckBox
                      label="적하목록 신고 여부"
                      checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                      onChange={() => {
                        handleTrelloCardOpCheckPoint({
                          shipmentId: v.id,
                          isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                          key: "didANcheckedAt",
                          isImport: false,
                        });
                      }}
                    />
                  }
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "4",
        lineTitle: "입항 완료",
        cards: trelloListData
          .filter((v) => v.projectStatus === "portEntryAndPrepareCustoms")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {v.incoterms.includes("C") && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}

                  {
                    <TrelloCardCheckBox
                      label="적하목록 신고 여부"
                      checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                      onChange={() => {
                        handleTrelloCardOpCheckPoint({
                          shipmentId: v.id,
                          isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                          key: "didANcheckedAt",
                          isImport: false,
                        });
                      }}
                    />
                  }
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },

      {
        lineKey: "5",
        lineTitle: "통관 완료",
        cards: trelloListData
          .filter((v) => v.projectStatus === "completeCustoms")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {
                    <TrelloCardCheckBox
                      label="적하목록 신고 여부"
                      checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                      onChange={() => {
                        handleTrelloCardOpCheckPoint({
                          shipmentId: v.id,
                          isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                          key: "didANcheckedAt",
                          isImport: false,
                        });
                      }}
                    />
                  }
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "8",
        lineTitle: "내륙 운송 중",
        cards: trelloListData
          .filter((v) => v.projectStatus === "delivering")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "9",
        lineTitle: "종료",
        cards: trelloListData
          .filter((v) => v.projectStatus === "finished")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {v.incoterms.includes("D") && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
    ];
  }, [
    handleTrelloCardClick,
    handleTrelloCardOpCheckPoint,
    sessionStorageTrelloIssueFilter,
    trelloListData,
  ]);

  return { exportCardListData };
}
