import { OriginInlandType } from "@sellernote/shared/src/types/common/common";

import { RadioGroupWithReactHookFormOption } from "../../components/RadioGroupWithReactHookForm";

const inlandTransportTypeRadioGroupOptionList: RadioGroupWithReactHookFormOption<OriginInlandType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    /**
     * 해당없음 옵션을 제공하는것이 admin에 의도된 기능인지 아닌지 불분명한 상황. PO(전상훈)와 논의해본 결과 의도는 아닌것으로 추정되어 해당 옵션을 일단 제거함
     * 추후 필요해지면 복구할 예 정
     */
    // {
    //   label: "해당 없음",
    //   value: "none",
    // },
  ];

export { inlandTransportTypeRadioGroupOptionList };
