import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/shared/src/constants";
import useSessionStorage from "@sellernote/shared/src/hooks/common/useSessionStorage";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import { FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS } from "@sellernote/shared/src/jotaiStates/trello";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/shared/src/states/forwarding/invoice";
import { ForwardingAdminUserListItem } from "@sellernote/shared/src/types/forwarding/adminUser";
import {
  ExportTrelloList,
  PartnerTrelloList,
  TrelloBidList,
  TrelloIssueFilter,
} from "@sellernote/shared/src/types/forwarding/trello";
import { checkIsInlandFarePaymentRequest } from "@sellernote/shared/src/utils/forwarding/admin/trello";

import { getServiceType } from "../utils";

export default function useCommonTrelloListLogic({
  adminData,
}: {
  adminData?: ForwardingAdminUserListItem[];
}) {
  const trelloCardId = useRecoilValue(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const history = useHistory();

  const { pathname } = useLocation();

  const serviceType = getServiceType({ pathname });

  const [sessionStorageTrelloIssueFilter, setSessionStorageTrelloIssueFilter] =
    useSessionStorage<TrelloIssueFilter | null>("trelloIssueFilter");

  const [showsTrelloDetailModal, setShowsTrelloDetailModal] = useState(false);
  const [bidId, setBidId] = useState(0);
  const [cardEndAddress, setCardEndAddress] = useState<null | string>(null);
  /** 쉽다-어드민 > 일반, 특송 리스트에서만 사용 */
  const [showsCheckIsPaymentModal, setShowsCheckIsPaymentModal] =
    useState(false);

  const openTrelloCardDetail = useCallback((bidId: number) => {
    setBidId(bidId);
    setShowsTrelloDetailModal(true);
  }, []);

  useEffect(() => {
    if (trelloCardId) {
      openTrelloCardDetail(trelloCardId);
    }
  }, [history.action, openTrelloCardDetail, trelloCardId]);

  const handleTrelloCardClick = useCallback(
    (cardData: TrelloBidList | ExportTrelloList | PartnerTrelloList) => {
      if (
        APP_NAME === "partner-admin" ||
        /** 정산 담당자가 통관 완료된 의뢰의 정산을 처리하지 못하게 막음 */
        currentAdminAuthInfo?.authority === "finance" ||
        serviceType === "export"
      ) {
        openTrelloCardDetail(cardData.id);
        return;
      }

      if (checkIsInlandFarePaymentRequest(cardData)) {
        setShowsCheckIsPaymentModal(true);
        setBidId(cardData.id);
        setCardEndAddress(cardData.endAddress);
        return;
      }

      openTrelloCardDetail(cardData.id);
    },
    [currentAdminAuthInfo?.authority, openTrelloCardDetail, serviceType]
  );

  const salesNameList = useMemo(() => {
    return (
      adminData &&
      adminData.filter((v: ForwardingAdminUserListItem) => {
        return v.isForwardingManager === true;
      })
    );
  }, [adminData]);

  const handleSessionStorageTrelloIssueFilterChange = (
    value: TrelloIssueFilter | null
  ) => {
    setSessionStorageTrelloIssueFilter(value);
  };

  return {
    sessionStorageTrelloIssueFilter,
    setShowsTrelloDetailModal,
    bidId,
    showsCheckIsPaymentModal,
    cardEndAddress,
    handleTrelloCardClick,
    serviceType,
    salesNameList,
    handleSessionStorageTrelloIssueFilterChange,
    showsTrelloDetailModal,
    openTrelloCardDetail,
    fetchTrelloListParams,
    setShowsCheckIsPaymentModal,
    setSessionStorageTrelloIssueFilter,
  };
}
