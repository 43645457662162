import { useState } from "react";
import { Box } from "@mui/material";

import { ForwardingAdminAttachment } from "@sellernote/shared/src/types/forwarding/trello";
import { omitWithEllipsis } from "@sellernote/shared/src/utils/common/string";
import Tab from "@sellernote/shared/src/sds-v2/components/tab/Tab";

import Preview from "./Preview";
import PreviewController from "./PreviewController";
import UploadField from "./UploadField";

export default function AttachmentSection({
  shipmentId,
  bidAccountPayableId,
  invoiceType,
  attachmentList,
  attachmentIndex,
  onAttachmentIndexChange,
  onOverlayConfirmModalOpen,
  getParsedList,
}: {
  shipmentId: number;
  bidAccountPayableId: number;
  invoiceType: string;
  attachmentList: ForwardingAdminAttachment[];
  attachmentIndex: number;
  onAttachmentIndexChange: (index: number) => void;
  onOverlayConfirmModalOpen: ({ id }: { id: number }) => void;
  getParsedList: (attachmentId: number) => void;
}) {
  const [s3Url, setS3Url] = useState("");
  const [imageWidth, setImageWidth] = useState(100);
  const [pdfScale, setPdfScale] = useState(1.3);
  const [pdfTotalPage, setPdfTotalPage] = useState(1);
  const [pdfPage, setPdfPage] = useState(1);
  const [fileExtension, setFileExtension] = useState("");

  const hasAttachment = Boolean(attachmentList.length);

  const handlePdfSuccessLoad = ({ numPages }: { numPages: number | null }) => {
    if (!numPages) return setPdfPage(0);
    setPdfTotalPage(numPages);
  };

  const tabDataList = attachmentList.map(({ name }, index) => ({
    tabValue: index,
    title: omitWithEllipsis({
      src: name,
      maxLength: 10,
      ellipsis: "..",
    }),
  }));

  return (
    <Box width={780}>
      {hasAttachment ? (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Tab
            direction="horizontal"
            uiType="file"
            selectedTab={attachmentIndex}
            onSelect={onAttachmentIndexChange}
            tabDataList={tabDataList}
          />

          <PreviewController
            shipmentId={shipmentId}
            bidAccountPayableId={bidAccountPayableId}
            invoiceType={invoiceType}
            attachment={attachmentList[attachmentIndex]}
            onAttachmentIndexChange={onAttachmentIndexChange}
            s3Url={s3Url}
            setImageWidth={setImageWidth}
            setPdfScale={setPdfScale}
            pdfTotalPage={pdfTotalPage}
            pdfPage={pdfPage}
            setPdfPage={setPdfPage}
            fileExtension={fileExtension}
            onOverlayConfirmModalOpen={onOverlayConfirmModalOpen}
            getParsedList={getParsedList}
          />

          <Preview
            attachment={attachmentList[attachmentIndex]}
            s3Url={s3Url}
            setS3Url={setS3Url}
            imageWidth={imageWidth}
            pdfScale={pdfScale}
            pdfPage={pdfPage}
            fileExtension={fileExtension}
            setFileExtension={setFileExtension}
            onPdfSuccessLoad={handlePdfSuccessLoad}
          />
        </Box>
      ) : (
        <UploadField
          shipmentId={shipmentId}
          bidAccountPayableId={bidAccountPayableId}
          invoiceType={invoiceType}
        />
      )}
    </Box>
  );
}
