import { Dispatch, SetStateAction } from "react";
import { Box, Button, Typography } from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";

import Modal from "../../../../../../components/Modal";

const INLAND_PARCEL_DOMAIN_LIST: PartnerBusinessArea[] = ["inland", "parcel"];

const INLAND_PARCEL_PARTNER_DELETE_SUCCESS =
  "매입관리 내 내륙운송사(화물택배사) 항목이 삭제 완료되었으며, 내륙운송 정보도 함께 삭제처리 되었습니다.";

function DeleteBidAccountPayableModal({
  opensDeleteBidAccountPayableModal,
  setOpensDeleteBidAccountPayableModal,
  bidAccountPayableId,
  processSuccessResponse,
  deleteInvoiceDomain,
  setDeleteInvoiceDomain,
}: {
  opensDeleteBidAccountPayableModal: boolean;
  setOpensDeleteBidAccountPayableModal: Dispatch<SetStateAction<boolean>>;
  bidAccountPayableId: number;
  processSuccessResponse: (message: string) => void;
  deleteInvoiceDomain: PartnerBusinessArea | undefined;
  setDeleteInvoiceDomain: (val: PartnerBusinessArea | undefined) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: deleteAccountPayable,
    ResponseHandler: ResponseHandlerOfDeleteAccountPayable,
  } = TRELLO_BID_QUERY.useDeleteAccountPayable();

  const handleBidAccountPayableModalDelete = () => {
    if (!deleteInvoiceDomain) return;

    const isInlandOrParcelDomain =
      INLAND_PARCEL_DOMAIN_LIST.includes(deleteInvoiceDomain);

    deleteAccountPayable(
      { pathParams: { bidAccountPayableId } },
      {
        onSuccess: () => {
          processSuccessResponse(
            isInlandOrParcelDomain
              ? INLAND_PARCEL_PARTNER_DELETE_SUCCESS
              : "삭제했습니다."
          );
          setOpensDeleteBidAccountPayableModal(false);
          setDeleteInvoiceDomain(undefined);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpened={opensDeleteBidAccountPayableModal}
        handleClose={() => setOpensDeleteBidAccountPayableModal(false)}
        modalBody={
          <>
            <Typography className="sads" variant="h6">
              삭제하시겠습니까?
            </Typography>

            <Box display="flex" gap={1} mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleBidAccountPayableModalDelete}
              >
                예
              </Button>

              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => setOpensDeleteBidAccountPayableModal(false)}
              >
                아니오
              </Button>
            </Box>
          </>
        }
      />

      {ResponseHandlerOfDeleteAccountPayable}
    </>
  );
}

export default DeleteBidAccountPayableModal;
