import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const container = styled.div`
  padding: 0;
  background-color: #fff;

  ${mobile(css`
    margin: 0;
    display: flex;
    justify-content: center;
  `)}

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw.gm-style-iw-c {
    border-radius: 2px;
    background-color: ${COLOR.bk_40};
    padding: 0px !important;
    transform: none;

    .gm-style-iw-ch {
      padding-top: 0px;
    }

    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  .gm-style-iw-t {
    right: -10px !important;
    top: -4px !important;
  }

  .gm-style-iw-tc {
    display: none;
  }

  .info-container {
    border-radius: 2px;
    padding: 0px !important;
    /** 마커 중앙에서 오른쪽으로 10px 떨어진 위치에 표시 (마커 크기의 절반(6px) + 마커와 정보창의 간격(4px))*/
    transform: translate(calc(50% + 10px), 100%);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);

    .departure,
    .destination {
      background-color: ${COLOR.bk};
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 2px;
    }

    .waypoint {
      background-color: ${COLOR.grayScale_700};
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 2px;
    }

    .title {
      ${setFontStyle("SubHead4")};
      color: ${TEXT_COLOR.white_1};
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .port {
      ${setFontStyle("Body4")};
      padding-left: 16px;
      color: ${TEXT_COLOR.white_1};
    }
  }

  .waypoint-marker {
    position: relative;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 0;
    box-sizing: border-box;
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #efbed2;
    border: 2px solid #dd6e97;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  }
`;

export default {
  container,
};
