import { Box } from "@mui/material";

import { ScheduleHistoryDetail } from "@sellernote/shared/src/types/forwarding/trello";

import CargoReadyLog from "./CargoReadyLog";
import ShipmentLog from "./ShipmentLog";

export default function DetailedScheduleChangeLog({
  row,
}: {
  row: ScheduleHistoryDetail;
}) {
  return (
    <Box sx={{ mt: 1 }}>
      {row.changeLog.type === "cargoReady" && <CargoReadyLog row={row} />}

      {row.changeLog.type === "routes" && <ShipmentLog row={row} />}
    </Box>
  );
}
