import { Button } from "@mui/material";

import { APP_NAME } from "@sellernote/shared/src/constants";
import { FreightType } from "@sellernote/shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/shared/src/types/forwarding/bid";
import { TrelloBidManagement } from "@sellernote/shared/src/types/forwarding/trello";

import useCheckCargoManifest from "./hooks/useCheckCargoManifest";
import useCheckNecessaryFile from "./hooks/useCheckNecessaryFile";

import AN_SUBMIT_AVAILABLE_STATUS_LIST from "./AN_SUBMIT_AVAILABLE_STATUS_LIST";
import PreviewAndFormModal from "./PreviewAndFormModal";

export default function ArrivalNoticeSubmit({
  isImport,
  shipmentId,
  freightType,
  shipmentStatus,
  BRN,
  management,
}: {
  isImport: boolean;
  shipmentId: number;
  freightType: FreightType;
  shipmentStatus: BidProjectStatus;
  BRN: string;
  management: TrelloBidManagement;
}) {
  const {
    hasCargoManifest,
    handleCargoManifestCheck,
    showsModal,
    handleModalClose,
  } = useCheckCargoManifest({
    shipmentId,
  });

  const { hasNecessaryFile } = useCheckNecessaryFile({ shipmentId, isImport });

  const canClickButton =
    hasNecessaryFile &&
    freightType === "FCL" &&
    AN_SUBMIT_AVAILABLE_STATUS_LIST[isImport ? "import" : "export"].includes(
      shipmentStatus
    );

  const isPartnerAdmin = APP_NAME === "partner-admin";

  if (isPartnerAdmin) return null;

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={handleCargoManifestCheck}
        disabled={!canClickButton}
      >
        적하목록 제출
      </Button>

      {showsModal && (
        <PreviewAndFormModal
          hasCargoManifest={hasCargoManifest}
          showsModal={showsModal}
          onModalClose={handleModalClose}
          shipmentId={shipmentId}
          isImport={isImport}
          BRN={BRN}
          management={management}
          freightType={freightType}
        />
      )}
    </>
  );
}
