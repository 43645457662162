import { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import { WithdrawalFormDetail } from "@sellernote/shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

function PurchasePriceSummary({
  companyType,
  withdrawalFormList,
}: {
  companyType: PartnerBusinessArea;
  withdrawalFormList: WithdrawalFormDetail[];
}) {
  const getTotalPrice = useCallback(
    (type: "totalPrice" | "vatPrice" | "finalPrice") => {
      return withdrawalFormList?.reduce((acc, cur) => {
        acc += cur[type];

        return acc;
      }, 0);
    },
    [withdrawalFormList]
  );

  /** 합계(외화) 값을 리턴 */
  const getTotalForeignCurrency = () => {
    return withdrawalFormList?.reduce((acc, cur) => {
      if (cur.currency && cur.currency !== "KRW") {
        acc += cur.amount * cur.unitPrice;
      } else {
        acc += 0;
      }

      return acc;
    }, 0);
  };

  return (
    <>
      <Grid item xs={companyType !== "foreign" ? 8.5 : 8.8}>
        <Typography
          variant="body1"
          fontWeight="bold"
          component="div"
          textAlign="right"
        >
          총 합계
        </Typography>
      </Grid>

      {companyType !== "foreign" && (
        <Grid item xs={2.5}>
          <Typography
            variant="body1"
            fontWeight="bold"
            component="div"
            textAlign="right"
          >
            {toThousandUnitFormat(getTotalPrice("totalPrice"))}
          </Typography>
        </Grid>
      )}

      {companyType !== "foreign" && (
        <Grid item xs={2}>
          <Typography
            variant="body1"
            fontWeight="bold"
            component="div"
            textAlign="right"
          >
            {toThousandUnitFormat(getTotalPrice("vatPrice"))}
          </Typography>
        </Grid>
      )}

      <Grid item xs={companyType !== "foreign" ? 2 : 3}>
        <Typography
          variant="body1"
          fontWeight="bold"
          component="div"
          textAlign="right"
        >
          {toThousandUnitFormat(getTotalForeignCurrency())}
        </Typography>
      </Grid>

      <Grid item xs={companyType !== "foreign" ? 2.5 : 3.5}>
        <Typography
          variant="body1"
          fontWeight="bold"
          component="div"
          textAlign="right"
        >
          {toThousandUnitFormat(getTotalPrice("finalPrice"))}
        </Typography>
      </Grid>
    </>
  );
}
export default PurchasePriceSummary;
