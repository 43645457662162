import { useMemo } from "react";

import {
  InvoiceType,
  PurchaseManagementInfo,
  SalesManagementInfo,
  TrelloProfitPurchaseData,
  TrelloProfitSalesData,
  TrelloPurchasePartnerType,
} from "@sellernote/shared/src/types/forwarding/trello";

export default function useProfitTableData({
  salesManagementInfo,
  purchaseManagementInfo,
}: {
  salesManagementInfo: SalesManagementInfo;
  purchaseManagementInfo: PurchaseManagementInfo;
}) {
  const salesManagementInfoKeys = useMemo(() => {
    return Object.keys(salesManagementInfo) as InvoiceType[];
  }, [salesManagementInfo]);

  const purchaseManagementInfoKeys = useMemo(() => {
    return Object.keys(purchaseManagementInfo) as TrelloPurchasePartnerType[];
  }, [purchaseManagementInfo]);

  const salesManagementTableData = useMemo(() => {
    const salesManagementData = salesManagementInfoKeys.reduce((acc, cur) => {
      if (cur === "etcDeposit" || cur === "warehouseReceipt") {
        salesManagementInfo[cur]?.forEach((n) => {
          acc.push({ invoiceType: cur, ...n });
        });
      } else {
        const data = {
          invoiceType: cur,
          price: salesManagementInfo[cur]?.price as number,
          issueFlag: salesManagementInfo[cur]?.issueFlag as boolean,
        };
        acc.push(data);
      }
      return acc;
    }, [] as TrelloProfitSalesData[]);

    return salesManagementData;
  }, [salesManagementInfoKeys, salesManagementInfo]);

  const purchaseManagementTableData = useMemo(() => {
    const purchaseManagementData = purchaseManagementInfoKeys.reduce(
      (acc, cur) => {
        purchaseManagementInfo[cur]?.forEach((v) => {
          acc.push({ partnerType: cur, ...v });
        });

        return acc;
      },
      [] as TrelloProfitPurchaseData[]
    );
    return purchaseManagementData;
  }, [purchaseManagementInfo, purchaseManagementInfoKeys]);

  return { salesManagementTableData, purchaseManagementTableData };
}
