import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { HBLInfo } from "@sellernote/shared/src/types/forwarding/trello";

import useDeleteFormContainer from "../../../../../../_hooks/container/useDeleteFormContainer";

const CONTAINER_REQUEST_TYPE = "bl" as const;

function useSaveHBLRequest({
  shipmentId,
  deletedContainerIdList,
  setDeletedContainerIdList,
}: {
  shipmentId: number;
  deletedContainerIdList: number[];
  setDeletedContainerIdList: (idList: number[]) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { deleteContainer } = useDeleteFormContainer({
    onSuccess: () => setDeletedContainerIdList([]),
  });

  const {
    mutate: saveHBL,
    isLoading: isSaveRequestLoading,
    ResponseHandler: ResponseHandlerOfSaveHBL,
  } = TRELLO_BID_QUERY.useSaveHBL({ shipmentId });

  const {
    mutate: tempSaveHBL,
    isLoading: isTempSaveRequestLoading,
    ResponseHandler: ResponseHandlerOfTempSaveHBL,
  } = TRELLO_BID_QUERY.useTempSaveHBL({ shipmentId });

  const { watch } = useFormContext<HBLInfo>();

  const queryClient = useQueryClient();

  const formState = watch();

  const commonPayload = useMemo(() => {
    const {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      fullETD,
      partnerName,
    } = formState;

    return {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      fullETD,
      partnerName,
      reqType: CONTAINER_REQUEST_TYPE,
    };
  }, [formState]);

  const handleRequestSuccess = async (snackbarMessage: string) => {
    handleSnackbarOpen(snackbarMessage);

    await Promise.all([
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
      ),
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({
          bidId: shipmentId,
        })
      ),
    ]);
  };

  const saveTemporaryHBLForm = () => {
    tempSaveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleRequestSuccess("BL이 임시저장됐습니다.");
        },
      }
    );
  };

  const saveHBLForm = () => {
    if (!formState.containers?.length) {
      handleSnackbarOpen("최소 컨테이너 1대가 필요합니다.", "error");
      return;
    }

    /** 의뢰에서 freightPaymentType값이 null인 경우가 있음 */
    if (!formState.freightPaymentType) {
      handleSnackbarOpen(
        "의뢰 상세에서 지급 유형을 먼저 설정해주세요.",
        "warning"
      );
      return;
    }

    saveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleRequestSuccess("BL이 저장됐습니다.");
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getOperationManagementCardDetailContainerInfo(
              { shipmentId }
            )
          );
        },
      }
    );
  };

  const handleTemporarySaveClick = () => {
    /** 삭제한 컨테이너 데이터가 있을 땐 삭제 API 호출 후 임시 저장 */
    if (deletedContainerIdList.length) {
      deleteContainer({
        idList: deletedContainerIdList,
        onSuccessCallback: saveTemporaryHBLForm,
      });
      return;
    }

    saveTemporaryHBLForm();
  };

  const handleSaveClick = () => {
    /** 삭제한 컨테이너 데이터가 있을 땐 삭제 API 호출 후 저장 */
    if (deletedContainerIdList.length) {
      deleteContainer({
        idList: deletedContainerIdList,
        onSuccessCallback: saveHBLForm,
      });
      return;
    }

    saveHBLForm();
  };

  return {
    handleTemporarySaveClick,
    handleSaveClick,
    isSaveRequestLoading,
    isTempSaveRequestLoading,

    ResponseHandlerOfSaveHBL,
    ResponseHandlerOfTempSaveHBL,
  };
}

export default useSaveHBLRequest;
