import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Divider, Grid } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import {
  Currency,
  ItemUnitMeasurement,
} from "@sellernote/shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/shared/src/types/forwarding/trello";
import { getRandomNumber } from "@sellernote/shared/src/utils/common/number";
import { calculateVATPrice } from "@sellernote/shared/src/utils/forwarding/admin/trello";

import FormList from "./FormList";
import PurchasePriceSummary from "./PurchasePriceSummary";

function PurchaseRequestForm({
  withdrawalFormList,
  currency,
  companyType,
  exchangeRateList,
  withdrawalItemList,
  trelloDetailData,
  currentExchangeRate,
}: {
  withdrawalFormList: WithdrawalFormDetail[];
  currency: Currency;
  companyType: PartnerBusinessArea;
  exchangeRateList: ExchangeRate[];
  withdrawalItemList: string[];
  trelloDetailData: TrelloBidDetail;
  currentExchangeRate: number;
}) {
  const { control, setValue } = useFormContext<WithdrawalFormListData>();

  const { insert, append } = useFieldArray({
    control,
    name: "withdrawalFormList",
  });

  const commonWithdrawalData = {
    currency,
    unitPrice: 0,
    itemUnitMeasurement: "" as ItemUnitMeasurement,
    amount: 0,
    totalPrice: 0,
    vatPrice: 0,
    finalPrice: 0,
    isVAT: false,
    name: "",
    note: "",
  };

  const getPriceCalculatedAtExchangeRate = useCallback(
    (row: WithdrawalFormDetail) => {
      if (row.currency === "KRW") {
        return Number((row.unitPrice * row.amount).toFixed(0));
      }

      const itemPrice = Number(
        (row.unitPrice * row.amount * currentExchangeRate).toFixed(0)
      );

      return itemPrice;
    },
    [currentExchangeRate]
  );

  const getTotalPrice = useCallback(
    (formData: WithdrawalFormDetail) => {
      let totalPrice = 0;

      if (companyType === "foreign") {
        totalPrice = 0;
      } else {
        totalPrice = getPriceCalculatedAtExchangeRate(formData);
      }

      return totalPrice;
    },
    [companyType, getPriceCalculatedAtExchangeRate]
  );

  const getVATPrice = useCallback(
    (formData: WithdrawalFormDetail, totalPrice: number) => {
      let VATPrice = 0;

      // 비과세 항목은 0으로 리턴한다.
      if (
        formData.name === "WFG" ||
        formData.name === "PFS" ||
        formData.name === "보험료"
      ) {
        return VATPrice;
      }

      if (companyType === "foreign") {
        VATPrice = 0;
      } else {
        VATPrice = calculateVATPrice(formData, totalPrice, companyType);
      }

      if (!VATPrice) {
        VATPrice = 0;
      }
      return VATPrice;
    },
    [companyType]
  );

  const getFinalPrice = useCallback(
    (formData: WithdrawalFormDetail, totalPrice: number, VATPrice: number) => {
      let finalPrice = 0;

      if (companyType === "foreign") {
        finalPrice = getPriceCalculatedAtExchangeRate(formData);
      } else {
        finalPrice = totalPrice + VATPrice;
      }

      return finalPrice;
    },
    [companyType, getPriceCalculatedAtExchangeRate]
  );

  const checkIsVAT = useCallback(
    (name: string | null) => name?.includes("VAT") ?? false,
    []
  );

  const getNewFormDataItem = useCallback(
    (formData: WithdrawalFormDetail): WithdrawalFormDetail => {
      // 부가세, 공급가, 합계만 수정을 하는 지 체크
      // originData는 수정 하기 전 데이터인데 전체 수정 상태에서 항목 추가를 할 경우 없는 경우가 있어 예외 처리

      const isVAT = checkIsVAT(formData.name);

      const totalPrice = getTotalPrice(formData);

      const VATPrice = getVATPrice(formData, totalPrice);

      const finalPrice = getFinalPrice(formData, totalPrice, VATPrice);

      const newFormData = {
        ...formData,
        totalPrice,
        vatPrice: VATPrice,
        finalPrice,
        isVAT,
      };

      return newFormData;
    },
    [checkIsVAT, getFinalPrice, getTotalPrice, getVATPrice]
  );

  return (
    <Grid container>
      <Grid
        item
        container
        columns={24}
        alignItems="center"
        sx={{ backgroundColor: grey[100], height: "32px" }}
      >
        <Grid item xs={5}>
          항목
        </Grid>

        <Grid item xs={2}>
          화폐
        </Grid>

        <Grid item xs={2}>
          단가
        </Grid>

        <Grid item xs={3.5}>
          단위
        </Grid>

        <Grid item xs={companyType !== "foreign" ? 1.5 : 1.7}>
          수량
        </Grid>

        {companyType !== "foreign" && (
          <Grid item xs={2.5}>
            공급가
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            부가세
          </Grid>
        )}

        <Grid item xs={companyType !== "foreign" ? 2 : 3}>
          합계(외화)
        </Grid>

        <Grid item xs={companyType !== "foreign" ? 2.5 : 3.8}>
          합계(KRW)
        </Grid>

        <Grid item xs={1}>
          삭제
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: blue[700] }} component={"div"} />
      </Grid>

      {withdrawalFormList?.map((v, index) => {
        return (
          <FormList
            key={v.id}
            formListIndex={index}
            formData={v}
            getNewFormDataItem={getNewFormDataItem}
            withdrawalItemList={withdrawalItemList}
            exchangeRateList={exchangeRateList}
            companyType={companyType}
            trelloDetailData={trelloDetailData}
          />
        );
      })}

      <Grid item container columns={24} mt={1}>
        <Grid item container xs={5} spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                if (withdrawalFormList) {
                  return insert(withdrawalFormList.length + 1, {
                    id: getRandomNumber(),
                    ...commonWithdrawalData,
                  });
                }
                return;
              }}
            >
              항목 추가
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                const newItems = Array.from({ length: 10 }, () => ({
                  id: getRandomNumber(),
                  ...commonWithdrawalData,
                }));
                append(newItems); // 한 번에 여러 항목 추가
              }}
            >
              항목 추가(10개)
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled={!withdrawalFormList?.length}
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                setValue("withdrawalFormList", []);
              }}
            >
              항목 초기화
            </Button>
          </Grid>
        </Grid>

        <PurchasePriceSummary
          companyType={companyType}
          withdrawalFormList={withdrawalFormList}
        />
      </Grid>
    </Grid>
  );
}

export default PurchaseRequestForm;
