import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/trello";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ContainerInfoForAN } from "@sellernote/shared/src/types/forwarding/trello";

import { checkUpdatedField } from "../utils";
import { COMMON_KEYS, EXPORT_KEYS, IMPORT_KEYS } from "../utils/constants";

import useDeleteFormContainer from "../../../../../../../../../_hooks/container/useDeleteFormContainer";

const CONTAINER_UPDATE_TYPE = "cargoManifest";

export default function useSaveCargoManifest({
  form,
  cargoManifestInfo,
  shipmentId,
  isImport,
  isEditMode,
  setIsEditMode,
  deletedIdList,
  setDeletedIdList,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
  isImport: boolean;
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  deletedIdList: number[];
  setDeletedIdList: (val: number[]) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    deleteContainer: deleteContainerAndUpdateContainer,
    ResponseHandlerOfDeleteContainerData,
  } = useDeleteFormContainer({
    onSuccess: () => {
      setDeletedIdList([]);
      updateCargoManifestInfo();
    },
  });

  const onSuccess = {
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getCargoManifest({ shipmentId })
        ),

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getOperationManagementCardDetailContainerInfo(
            { shipmentId }
          )
        ),

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
        ),
      ]);

      handleSnackbarOpen("적하목록 정보를 성공적으로 변경했습니다.");
    },
  };

  const {
    mutate: updateCargoManifestData,
    ResponseHandler: ResponseHandlerOfUpdateCargoManifest,
  } = TRELLO_BID_QUERY.useUpdateCargoManifest({
    shipmentId,
    setIsEditMode,
  });

  const updateCargoManifestInfo = () => {
    if (isEditMode) {
      const updatedCommonFields = checkUpdatedField<
        CargoManifestInfoProps,
        string | number | ContainerInfoForAN[]
      >({
        form,
        originalData: cargoManifestInfo,
        keysToCheck: COMMON_KEYS,
      });

      const updatedFields = isImport
        ? checkUpdatedField<CargoManifestInfoProps["importation"], string>({
            form: form.importation,
            originalData: cargoManifestInfo.importation,
            keysToCheck: IMPORT_KEYS,
          })
        : checkUpdatedField<CargoManifestInfoProps["exportation"], string>({
            form: form.exportation,
            originalData: cargoManifestInfo.exportation,
            keysToCheck: EXPORT_KEYS,
          });

      const hasContainer = Boolean(form.containers?.length);

      /** 수출에서는 packageCount를 보내지 않음 */
      const containerPayload = (() => {
        if (isImport) return form.containers;

        return form.containers?.map((container) => ({
          id: container.id,
          containerNo: container.containerNo,
          sealNo: container.sealNo,
          containerTypeForCustoms: container.containerTypeForCustoms,
        }));
      })();

      const payload = {
        ...updatedCommonFields,
        ...updatedFields,
        ...(hasContainer ? { containers: containerPayload } : {}),
        ...(hasContainer ? { packageCount: form.packageCount } : {}),
        reqType: CONTAINER_UPDATE_TYPE,
      };

      updateCargoManifestData(payload, onSuccess);
    }

    setIsEditMode((prev) => !prev);
  };

  const updateCargoManifest = () => {
    if (deletedIdList.length) {
      deleteContainerAndUpdateContainer({ idList: deletedIdList });
      return;
    }

    updateCargoManifestInfo();
  };

  return {
    updateCargoManifest,
    ResponseHandlerOfDeleteContainerData,
    ResponseHandlerOfUpdateCargoManifest,
  };
}
