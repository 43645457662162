import { ScheduleHistoryDetail } from "@sellernote/shared/src/types/forwarding/trello";

import ScheduleLogItem from "./ScheduleLogItem";

export default function ShipmentLog({ row }: { row: ScheduleHistoryDetail }) {
  /** 스케줄 입력의 경우 changeLog.after의 첫 번째 요소를 사용함 */
  if (row.changeLog.actionType === "input") {
    return <ScheduleLogItem log={row.changeLog.after[0]} />;
  }

  /** 스케줄 변경의 경우 */
  return (
    <>
      {row.changeLog.before.map((log) => (
        <ScheduleLogItem key={log.shipName} log={log} isStrikeThrough />
      ))}
      - - -
      {row.changeLog.after.map((log) => (
        <ScheduleLogItem key={log.shipName} log={log} />
      ))}
    </>
  );
}
