import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { BidServiceType } from "@sellernote/shared/src/types/forwarding/bid";
import {
  ExportTrelloList,
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/shared/src/types/forwarding/trello";

import useGetTrelloQueryKeyGen from "./useGetTrelloQueryKeyGen";

/**
 *  운영 관리 카드 리스트에서 적하목록 신고 여부 체크 요청 후 데이터 갱신을 하기 위한 커스텀 훅
 *   - 운영 관리 리스트의 요청 시간이 오래걸리기 때문에 재요청하지 않고 해당 데이터만 찾아서 쿼리 데이터를 업데이트 해준다.
 */
export default function useRefreshTrelloListForListPage<T>({
  trelloListData,
  fetchTrelloListParams,
  serviceType,
}: {
  trelloListData: TrelloBidList[] | ExportTrelloList[];
  fetchTrelloListParams: FetchTrelloListParams;
  serviceType: Exclude<BidServiceType, "oceanTicket"> | undefined;
}) {
  const queryClient = useQueryClient();

  const {
    importationTrelloListQueryKey,
    exportationTrelloListQueryKey,
    importationTrelloFinishedListQueryKey,
    exportationTrelloFinishedListQueryKey,
  } = useGetTrelloQueryKeyGen({
    fetchTrelloListParams,
    serviceType: serviceType,
  });

  const refreshTrelloListForListPage = useCallback(
    ({
      shipmentId,
      renewData,
      renewOpCheckPointData,
      isFinished,
      isImport,
    }: {
      shipmentId: number;
      renewData?: T;
      renewOpCheckPointData?: T;
      isFinished: boolean;
      isImport: boolean;
    }) => {
      /** concat으로 합쳐진 진행중 + 종료 리스트 데이터에서 분리 */
      const separatedTrelloListByFinishedStatus = [...trelloListData].filter(
        (v) => {
          if (isFinished) {
            return v.projectStatus === "finished";
          }
          return v.projectStatus !== "finished";
        }
      );

      /** API 요청으로 인해 변경된 값을 반영한 새로운 리스트 데이터 */
      const updatedTrelloListData = separatedTrelloListByFinishedStatus.map(
        (v) => {
          if (v.id === shipmentId) {
            return {
              ...v,
              management: { ...v.management, ...renewData },
              opCheckPoint: { ...v.opCheckPoint, ...renewOpCheckPointData },
            };
          }
          return v;
        }
      );

      /** 종료 리스트 재요청 */
      if (isFinished) {
        queryClient.setQueryData(
          isImport
            ? importationTrelloFinishedListQueryKey
            : exportationTrelloFinishedListQueryKey,
          { data: updatedTrelloListData }
        );

        return;
      }

      /** 진행중 리스트 재요청 */
      queryClient.setQueryData(
        isImport
          ? importationTrelloListQueryKey
          : exportationTrelloListQueryKey,
        { data: updatedTrelloListData }
      );

      return;
    },
    [
      exportationTrelloFinishedListQueryKey,
      exportationTrelloListQueryKey,
      importationTrelloFinishedListQueryKey,
      importationTrelloListQueryKey,
      queryClient,
      trelloListData,
    ]
  );

  return { refreshTrelloListForListPage };
}
