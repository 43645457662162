import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import { APP_NAME } from "@sellernote/shared/src/constants";
import { AdminBidList } from "@sellernote/shared/src/types/forwarding/adminBid";
import { ForwardingAdminUserListItem } from "@sellernote/shared/src/types/forwarding/adminUser";
import { CustomsAdminBidList } from "@sellernote/shared/src/types/forwarding/customsAdminBid";
import { getBidTableItemName } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
  changeExportProjectStatusNameToKr,
} from "@sellernote/shared/src/utils/forwarding/bid";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../components/LegacyTable";

import BidDeleteModal from "./BidDeleteModal";
import ShipmentApplyInfo from "./ShipmentApplyInfo";
import ShipmentTransInfo from "./ShipmentTransInfo";

function ShipmentTable({
  bidList,
  adminData,
  TableHeadPanel,
  refetchBidListData,
  pagination,
  isLoadingBidList,
  isLoadingForGetAdminUserList,
  filterDataReset,
}: {
  bidList?: AdminBidList[] | CustomsAdminBidList[];
  adminFilterArray?: {
    text: string;
    value: number;
  }[];
  adminData?: ForwardingAdminUserListItem[];
  TableHeadPanel: {
    ForwardingManagerFilter: JSX.Element;
    FreightAndIncotermsHeadFilter: JSX.Element;
  };
  refetchBidListData?: () => void;
  pagination: {
    rowsPerPageOptions: number[];
    totalCount: number;
    perPage: number;
    setPerPage: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
  };
  isLoadingBidList?: boolean;
  isLoadingForGetAdminUserList?: boolean;
  filterDataReset?: () => void;
}) {
  type CellId =
    | keyof AdminBidList
    | keyof CustomsAdminBidList
    | "bidItemList"
    | "productName"
    | "bidApplyInfo"
    | "delete"
    | "reset"
    | "region";

  const history = useHistory();

  const [showsBidDeleteModal, setShowsBidDeleteModal] = useState(false);
  const [bidId, setBidId] = useState<number>(0);

  const handleBidDetailMove = useCallback(
    (bidId: number) => {
      return history.push(`/bid/detail/${bidId}`);
    },
    [history]
  );

  const isLoading = isLoadingBidList || isLoadingForGetAdminUserList;

  const handleBidDeleteModalOpen = useCallback(
    (e: MouseEvent, bidId: number) => {
      e.stopPropagation();
      setBidId(bidId);
      setShowsBidDeleteModal(true);
    },
    []
  );

  const getManagerName = useCallback(
    (tableValue) => {
      const findAdmin = adminData?.find((v) => {
        return v.id === tableValue.forwardingManagerId;
      });

      return findAdmin ? findAdmin.name : "-";
    },
    [adminData]
  );

  const RefreshButton = useMemo(() => {
    return (
      <Tooltip title="리스트 데이터 새로고침">
        <IconButton onClick={refetchBidListData}>
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    );
  }, [refetchBidListData]);

  const HeadCells = useMemo(() => {
    const headCells: LegacyTableHeadCell<CellId>[] = [
      {
        id: "reset",
        disablePadding: false,
        label: RefreshButton,
        width: 10,
      },
      {
        id: "id",
        disablePadding: false,
        label: "운송의뢰번호",
        width: 110,
      },
      {
        id: "isImport",
        disablePadding: false,
        label: "수출/입",
        width: 90,
      },
      {
        id: "user",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      ...(APP_NAME === "shipda-admin"
        ? [
            {
              id: "teamName",
              disablePadding: false,
              label: "팀명",
              width: 100,
            } as LegacyTableHeadCell<CellId>,
          ]
        : []),
      {
        id: "management",
        disablePadding: false,
        label: "포워딩 담당자",
        width: 120,
        filter: TableHeadPanel.ForwardingManagerFilter,
      },
      {
        id: "bidItemList",
        disablePadding: false,
        label: "운송 정보",
        width: 250,
        filter: TableHeadPanel.FreightAndIncotermsHeadFilter,
      },
      {
        id: "productName",
        disablePadding: false,
        label: "상품명",
        width: 140,
      },
      {
        id: "bidApplyInfo",
        disablePadding: false,
        label: "",
        width: 250,
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
        width: 180,
      },
      {
        id: "projectStatus",
        disablePadding: false,
        label: "세부 상태",
        width: 140,
      },
      ...(APP_NAME === "shipda-admin"
        ? [
            {
              id: "delete",
              disablePadding: false,
              label: "",
              width: 140,
            } as LegacyTableHeadCell<CellId>,
          ]
        : []),
    ];

    return headCells;
  }, [
    RefreshButton,
    TableHeadPanel.ForwardingManagerFilter,
    TableHeadPanel.FreightAndIncotermsHeadFilter,
  ]);

  const rows = useMemo(() => {
    if (!bidList) return [];

    return bidList.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        handleRowClick: () => handleBidDetailMove(v.id),
        backgroundColor: v.serviceType === "oceanTicket" ? blue[50] : "",
        reset: "",
        id: v.id,
        isImport: v.isImport ? "수입" : "수출",
        user: v.team.company,
        ...(APP_NAME === "shipda-admin" && { teamName: v.team.name }),
        management: getManagerName(v.team),
        bidItemList: <ShipmentTransInfo adminBidListData={v} />,
        productName: getBidTableItemName({
          items: v.itemGroupNameList?.map((v) => ({
            name: v,
          })),
        }),
        bidApplyInfo: <ShipmentApplyInfo adminBidListData={v} />,
        status: changeBidStatusNameToKr(v.status),
        projectStatus:
          APP_NAME === "partner-admin"
            ? changeBidProjectStatusNameToKr(v.projectStatus)
            : v.isImport
            ? changeBidProjectStatusNameToKr(v.projectStatus)
            : changeExportProjectStatusNameToKr(v.projectStatus),
        ...(APP_NAME === "shipda-admin" && {
          delete: (
            <IconButton
              aria-label="delete"
              onClick={(e) => handleBidDeleteModalOpen(e, v.id)}
            >
              <DeleteIcon />
            </IconButton>
          ),
        }),
      };

      return row;
    });
  }, [bidList, getManagerName, handleBidDetailMove, handleBidDeleteModalOpen]);

  return (
    <>
      <LegacyTable
        headCells={HeadCells}
        rows={rows}
        pagination={pagination}
        toolbarItems={{
          left: [
            <Typography key="total">총 {pagination.totalCount}건</Typography>,
          ],
        }}
        isLoading={isLoading}
        resetHeadFilter={filterDataReset}
      />

      {showsBidDeleteModal && (
        <BidDeleteModal
          showsBidDeleteModal={showsBidDeleteModal}
          setShowsBidDeleteModal={setShowsBidDeleteModal}
          pagination={pagination}
          bidId={bidId}
        />
      )}
    </>
  );
}

export default ShipmentTable;
