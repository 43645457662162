import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  HouseDeliveryOrderDocumentForm,
  HouseDeliveryOrderDocumentFormType,
} from "@sellernote/shared/src/types/forwarding/trello";
import {
  getDocumentDataCbm,
  getDocumentDataWeight,
  getHouseDeliveryOrderDocumentTextFieldLabel,
} from "@sellernote/shared/src/utils/forwarding/admin/trello";

import TextField from "../../../../../../../components/TextField";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "../../../../../../../../constants/forwarding/adminZIndexLevelRecord";
import useHouseDeliveryOrderDocumentPreview from "./useHouseDeliveryOrderDocumentPreview";
import Styled from "./index.styles";

const HouseDeliveryOrderDocument = ({
  houseDeliveryOrderDocumentVisible,
  setHouseDeliveryOrderDocumentVisible,
  userCompany,
  bidId,
}: {
  houseDeliveryOrderDocumentVisible: boolean;
  setHouseDeliveryOrderDocumentVisible: Dispatch<SetStateAction<boolean>>;
  userCompany: string;
  bidId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: houseDeliveryOrderDocumentDefaultData } =
    TRELLO_BID_QUERY.useGetHouseDeliveryOrderDocumentDefaultData(bidId);

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<HouseDeliveryOrderDocumentForm>();

  const {
    mutate: updateDocument,
    isLoading,
    ResponseHandler: ResponseHandlerOfUpdateDocument,
  } = TRELLO_BID_QUERY.useUpdateDocument(bidId);

  const {
    mutate: getFileDownloadUrl,
    ResponseHandler: ResponseHandlerOfGetFileDownloadUrl,
  } = ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const houseDeliveryOrderHtml = useHouseDeliveryOrderDocumentPreview(
    watch,
    houseDeliveryOrderDocumentDefaultData?.containersNumberInfo,
    houseDeliveryOrderDocumentDefaultData?.docNum || "(서류 업로드 후 생성)"
  );

  useEffect(() => {
    reset({
      shipper: houseDeliveryOrderDocumentDefaultData?.shipper,
      blNumber: houseDeliveryOrderDocumentDefaultData?.blNumber,
      prnm: houseDeliveryOrderDocumentDefaultData?.prnm,
      departureInfo: houseDeliveryOrderDocumentDefaultData?.departureInfo,
      arrivalInfo: houseDeliveryOrderDocumentDefaultData?.arrivalInfo,
      packageInfo: houseDeliveryOrderDocumentDefaultData?.packageInfo,
      consignee:
        houseDeliveryOrderDocumentDefaultData?.consignee ||
        houseDeliveryOrderDocumentDefaultData?.company,
      arrivalDate: houseDeliveryOrderDocumentDefaultData?.arrivalDate,
      notiInfo: "same as cnee",
      voyageNumber: houseDeliveryOrderDocumentDefaultData?.voyageNumber,
      cbm: houseDeliveryOrderDocumentDefaultData?.invoiceCbm
        ? getDocumentDataCbm(houseDeliveryOrderDocumentDefaultData?.invoiceCbm)
        : houseDeliveryOrderDocumentDefaultData?.cbm,
      weight: houseDeliveryOrderDocumentDefaultData?.invoiceCbm
        ? getDocumentDataWeight(
            houseDeliveryOrderDocumentDefaultData?.invoiceCbm
          )
        : houseDeliveryOrderDocumentDefaultData?.weight,
    });
  }, [
    houseDeliveryOrderDocumentDefaultData?.arrivalDate,
    houseDeliveryOrderDocumentDefaultData?.arrivalInfo,
    houseDeliveryOrderDocumentDefaultData?.blNumber,
    houseDeliveryOrderDocumentDefaultData?.cbm,
    houseDeliveryOrderDocumentDefaultData?.company,
    houseDeliveryOrderDocumentDefaultData?.consignee,
    houseDeliveryOrderDocumentDefaultData?.departureInfo,
    houseDeliveryOrderDocumentDefaultData?.invoiceCbm,
    houseDeliveryOrderDocumentDefaultData?.packageInfo,
    houseDeliveryOrderDocumentDefaultData?.prnm,
    houseDeliveryOrderDocumentDefaultData?.shipper,
    houseDeliveryOrderDocumentDefaultData?.voyageNumber,
    houseDeliveryOrderDocumentDefaultData?.weight,
    reset,
    userCompany,
  ]);

  const handleDocumentDownload: SubmitHandler<HouseDeliveryOrderDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "houseDo" } },
          {
            onSuccess: ({ data: uploadSuccessData }) => {
              getFileDownloadUrl(
                {
                  pathParams: {
                    key: uploadSuccessData.pdfKey,
                  },
                },
                {
                  onSuccess: async ({ data: downloadSuccessData }) => {
                    const blobFile = await axios.get(downloadSuccessData.url, {
                      responseType: "blob",
                    });

                    fileDownload(blobFile.data, uploadSuccessData.fileName);
                    queryClient.invalidateQueries(
                      TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
                    );
                  },

                  onError: () => {
                    handleSnackbarOpen("다운로드에 실패했습니다.", "error");
                  },
                }
              );
            },

            onError: () => {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            },
          }
        );
      },
      [
        bidId,
        getFileDownloadUrl,
        handleSnackbarOpen,
        queryClient,
        updateDocument,
      ]
    );

  const handleDocumentUpload: SubmitHandler<HouseDeliveryOrderDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "houseDo" } },
          {
            onSuccess: () => {
              handleSnackbarOpen("업로드에 성공했습니다.", "success");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
              );
            },

            onError: () => {
              handleSnackbarOpen("업로드에 실패했습니다.", "error");
            },
          }
        );
      },
      [bidId, handleSnackbarOpen, queryClient, updateDocument]
    );

  const handleDocumentDrawerClose = useCallback(() => {
    setHouseDeliveryOrderDocumentVisible(false);
  }, [setHouseDeliveryOrderDocumentVisible]);

  const handleTextFieldClear = useCallback(
    (formType: HouseDeliveryOrderDocumentFormType) => {
      return () => {
        reset({ ...watch(), [`${formType}`]: "" });
      };
    },
    [reset, watch]
  );

  const FormTextField = useCallback(
    ({
      textFieldError,
      textFieldType,
      multiline,
      rows,
      width,
    }: {
      textFieldError: FieldError | undefined;
      textFieldType: HouseDeliveryOrderDocumentFormType;
      multiline?: boolean;
      rows?: number;
      width?: number;
    }) => {
      return (
        <Controller
          control={control}
          name={textFieldType}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                multiline={multiline}
                rows={rows}
                inputRef={ref}
                sx={{ width: width }}
                handleTextFieldClear={handleTextFieldClear(textFieldType)}
                label={getHouseDeliveryOrderDocumentTextFieldLabel(
                  textFieldType
                )}
                error={textFieldError && textFieldError.type === "required"}
                helperText={
                  textFieldError &&
                  textFieldError.type === "required" &&
                  "필수 입력입니다."
                }
              />
            );
          }}
        />
      );
    },
    [control, handleTextFieldClear]
  );

  const handleNotiInfoValueToSellerNoteChange = useCallback(() => {
    reset({
      ...watch(),
      notiInfo:
        "SELLERNOTE  9F, 89, Saemunan-ro, Jongno-gu, Seoul, Republic of Korea",
    });
  }, [reset, watch]);

  const handleNotiInfoValueToConsigneeChange = useCallback(() => {
    reset({
      ...watch(),
      notiInfo: houseDeliveryOrderDocumentDefaultData?.consignee || userCompany,
    });
  }, [
    houseDeliveryOrderDocumentDefaultData?.consignee,
    reset,
    userCompany,
    watch,
  ]);

  return (
    <Drawer
      anchor={"left"}
      sx={{ zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.drawer }}
      open={houseDeliveryOrderDocumentVisible}
      onClose={handleDocumentDrawerClose}
    >
      <Styled.drawerBody>
        <Styled.header>
          <div>
            {houseDeliveryOrderDocumentDefaultData?.docNum ||
              "(서류 업로드 후 생성)"}
          </div>
        </Styled.header>

        <Divider />

        <Styled.documentContainer>
          <Box
            component="form"
            autoComplete="off"
            className="create-container"
            onSubmit={handleSubmit(handleDocumentUpload)}
          >
            <div className="title">
              <Typography variant="h6" component="div">
                DELIVERY ORDER
              </Typography>
            </div>

            <div className="input-container">
              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.shipper}
                  textFieldType={"shipper"}
                  width={400}
                  multiline={true}
                  rows={3}
                />

                <FormTextField
                  textFieldError={errors.blNumber}
                  textFieldType={"blNumber"}
                />
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.consignee}
                  textFieldType={"consignee"}
                  width={400}
                  multiline={true}
                  rows={3}
                />
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.notiInfo}
                  textFieldType={"notiInfo"}
                  width={400}
                  multiline={true}
                  rows={3}
                />

                <div>
                  <Button
                    variant="contained"
                    onClick={handleNotiInfoValueToConsigneeChange}
                  >
                    CNEE
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={handleNotiInfoValueToSellerNoteChange}
                  >
                    Seller Note
                  </Button>
                </div>
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.departureInfo}
                  textFieldType={"departureInfo"}
                />

                <FormTextField
                  textFieldError={errors.arrivalInfo}
                  textFieldType={"arrivalInfo"}
                  width={400}
                />
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.arrivalDate}
                  textFieldType={"arrivalDate"}
                />

                <FormTextField
                  textFieldError={errors.voyageNumber}
                  textFieldType={"voyageNumber"}
                  width={400}
                />
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.prnm}
                  textFieldType={"prnm"}
                  width={400}
                  multiline={true}
                  rows={3}
                />
              </div>

              <div className="text-field-container">
                <FormTextField
                  textFieldError={errors.packageInfo}
                  textFieldType={"packageInfo"}
                />

                <FormTextField
                  textFieldError={errors.weight}
                  textFieldType={"weight"}
                />

                <FormTextField
                  textFieldError={errors.cbm}
                  textFieldType={"cbm"}
                />
              </div>
            </div>

            <Styled.uploadButton>
              <Button color="success" variant="contained" type="submit">
                {isLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "업로드"
                )}
              </Button>
            </Styled.uploadButton>

            <Styled.downloadButton>
              <Button
                variant="contained"
                onClick={handleSubmit(handleDocumentDownload)}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "다운로드"
                )}
              </Button>
            </Styled.downloadButton>
          </Box>

          <Styled.preview
            dangerouslySetInnerHTML={{
              __html: houseDeliveryOrderHtml,
            }}
          />
        </Styled.documentContainer>
      </Styled.drawerBody>

      {ResponseHandlerOfGetFileDownloadUrl}
      {ResponseHandlerOfUpdateDocument}
    </Drawer>
  );
};

export default HouseDeliveryOrderDocument;
