import { Typography } from "@mui/material";

import { ScheduleHistoryDetail } from "@sellernote/shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/shared/src/utils/common/date";

export default function CargoReadyLog({ row }: { row: ScheduleHistoryDetail }) {
  const prevCargoReady = row.changeLog.before[0].cargoReady;
  const cargoReady = row.changeLog.after[0].cargoReady;

  /** 스케줄 입력인 경우  */
  if (row.changeLog.actionType === "input") {
    return (
      <Typography>
        {formatDate({ date: cargoReady, type: "YY_MM_DD" })}
      </Typography>
    );
  }

  /** 스케줄 변경인 경우, 이전 카고레디 날짜까지 노출 */
  return (
    <Typography>
      <s>{formatDate({ date: prevCargoReady, type: "YY_MM_DD" })}</s> |{" "}
      {formatDate({ date: cargoReady, type: "YY_MM_DD" })}
    </Typography>
  );
}
